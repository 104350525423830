@keyframes fade-in-left {
  from {
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.c-animation--fade-in-left {
  animation: fade-in-left 500ms var(--timing-func) both;
}

@keyframes fade-in-right {
  from {
    transform: translate3d(50%, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.c-animation--fade-in-right {
  animation: fade-in-right 500ms var(--timing-func) both;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.c-animation--fade-out {
  animation: fade-out 0.7s ease-out both;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.c-animation--fade-in {
  animation: fade-in 0.7s ease-out both;
}

@keyframes fade-in-down {
  from {
    transform: translate3d(0, -20%, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.c-animation--fade-in-down {
  animation: fade-in-down 1000ms cubic-bezier(0.77, 0, 0.175, 1) both;
}

@keyframes fade-in-up {
  from {
    transform: translate3d(0, 20%, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.c-animation--fade-in-up {
  animation: fade-in-up 1000ms cubic-bezier(0.77, 0, 0.175, 1) both;
}
