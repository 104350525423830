@import 'sanitize.css';

html,
body {
  position: relative;
  font-size: 100%;
}

body {
  min-width: 20rem;
  color: var(--color-black);

  font-weight: normal;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;

  background-color: var(--color-sand);
}

/* @media only screen and (orientation: landscape) {
  body {
    height: 100vw;
    transform: rotate(90deg);
  }
} */

p {
  margin: 0;

  font-size: 0.875rem;
  line-height: 1.4;
  letter-spacing: 0.3px;

  @media (--mobile-m) {
    font-size: 1rem;
    line-height: 1.5;
  }
}

h1,
h2 {
  font-weight: inherit;
  font-family: 'Oswald', sans-serif;
}

h1 {
  margin-top: 0;
  font-size: 2rem;
  line-height: 1;

  @media (--tablet) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 2rem;
  line-height: 1;

  @media (--tablet) {
    font-size: 2.5rem;
  }
}

a {
  color: var(--color-black);
  transition: color 0.25s;
}

a:hover {
  color: var(--color-orange);
}

/* *:focus {
  outline: none;
} */

button,
select {
  cursor: pointer;
}

hr {
  margin: 3rem 0 2rem;
}

/* body::-webkit-scrollbar-track {
  background-color: rgba(var(--color-black), 0.96);
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: var(--color-black);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-blue);
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */

/* * {
  border: 1px solid red;
} */
