.c-textarea {
  width: 100%;
  min-height: 250px;

  padding-left: 0.6rem;

  color: var(--color-black);
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  background: var(--color-sand-light);

  border: solid 1px var(--color-sand-light);
  border-radius: var(--border-radius--small);

  box-shadow: var(--shadow-light);
}
