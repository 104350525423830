.c-select {
  width: 100%;
  height: 40px;

  padding-left: 0.5rem;
  color: rgba(28, 28, 28, 0.6);

  font-size: 1rem;

  /* SVG background image */
  background:
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%231c1c1c'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-color: var(--color-white);
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) 16px;
  background-size: 16px;
  border: none;

  /* needed */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.c-select:disabled {
  background:
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23616161'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-color: var(--color-disabled-light);
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) 16px;
  background-size: 16px;
  border: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
