.c-bg__h-group-r {
  @mixin bg-hero group-r;
}

/* .c-bg__hero-group-r {
  background-image: url(../img/hero-group-r-1024px.jpg);
  background-position: center top;

  @media (--laptop) {
    background-image: url(../img/hero-group-r-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-group-r-1920px.jpg);
  }
} */

.c-bg__group-r-desc {
  background-image: url(../img/desc-group-r-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/desc-group-r-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/desc-group-r-1920px.jpg);
  }
}

.c-bg__group-r-spec {
  background-image: url(../img/spec-group-r-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/spec-group-r-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/spec-group-r-1920px.jpg);
  }
}

.c-bg__footer-group-r {
  background-image: url(../img/section-footer-group-r-1024px.jpg);
  background-position: center top;

  @media (--laptop) {
    background-image: url(../img/section-footer-group-r-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-footer-group-r-1920px.jpg);
  }
}

.c-bg__hero-group-r-equipment {
  background-image: url(../img/hero-group-r-equipment-1024px.jpg);
  background-position: center bottom;

  @media (--laptop) {
    background-image: url(../img/hero-group-r-equipment-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-group-r-equipment-1920px.jpg);
  }
}

.c-bg__hero-group-r-equipment::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  content: '';

  @media (--tablet) {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.c-bg__gallery-group-r-01 {
  background-image: url(../img/gallery-bg-group-r-001-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-r-001-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-r-001-1920px.jpg);
  }
}

.c-bg__gallery-group-r-01::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}

.c-bg__gallery-group-r-02 {
  background-image: url(../img/gallery-bg-group-r-002-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-r-002-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-r-002-1920px.jpg);
  }
}

.c-bg__gallery-group-r-02::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}

.c-bg__gallery-group-r-03 {
  background-image: url(../img/gallery-bg-group-r-003-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-r-003-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-r-003-1920px.jpg);
  }
}

.c-bg__gallery-group-r-03::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}
