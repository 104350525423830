.c-accordion {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  border-radius: 15px;
  box-shadow: var(--shadow-light);
}

.c-accordion input[type='checkbox'] {
  display: none;
}

.c-accordion__item {
  position: relative;
  max-height: 53px;
  overflow: hidden;
}

.c-accordion__item:first-of-type {
}

.c-accordion__item:last-of-type {
}

.c-accordion__item > ul {
  padding-left: 0;
  list-style-type: none;
}

.c-accordion-item__title {
  position: relative;
  display: flex;
  align-items: center;

  height: 53px;

  padding-right: 2.5rem;
  padding-left: 1.1rem;

  color: var(--color-orange);
  font-weight: 500;
  font-size: 16px;
  font-family: Oswald, sans-serif;
  font-style: normal;
  line-height: 1.2;
  text-transform: uppercase;

  background: var(--color-black);

  cursor: pointer;

  transition: all 0.25s;
}

.c-accordion-item__title::after {
  position: absolute;
  top: 15px;
  right: 12px;
  width: 19px;
  height: 19px;

  background-color: var(--color-orange);

  transform: rotate(-90deg);
  transition: all 0.25s;
  mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.06 6.94a1.5 1.5 0 1 0-2.12 2.12l2.12-2.12zM12 16l-1.06 1.06a1.5 1.5 0 0 0 2.12 0L12 16zm9.06-6.94a1.5 1.5 0 0 0-2.12-2.12l2.12 2.12zm-18.12 0l8 8 2.12-2.12-8-8-2.12 2.12zm10.12 8l8-8-2.12-2.12-8 8 2.12 2.12z'/%3E%3C/svg%3E");
  content: '';
}

.c-accordion-item__title:hover {
  color: var(--color-orange-hover);
  background: var(--color-black-hover);
}

.c-accordion-item__title:hover::after {
  background: var(--color-orange-hover);
}

.c-accordion__subitem {
  position: relative;
  max-height: 53px;
  overflow: hidden;
}

.c-accordion-subitem__title {
  position: relative;
  display: flex;
  align-items: center;

  height: 53px;

  padding-right: 2.5rem;
  padding-left: 1rem;

  color: var(--color-orange);
  font-weight: 500;
  font-size: 16px;
  font-family: Oswald, sans-serif;
  font-style: normal;
  line-height: 1.2;
  text-transform: uppercase;

  background: var(--color-black-light);

  cursor: pointer;

  transition: all 0.25s;
}

.c-accordion-subitem__title::after {
  position: absolute;
  top: 15px;
  right: 12px;
  width: 19px;
  height: 19px;

  background-color: var(--color-orange);

  transform: rotate(-90deg);
  transition: all 0.25s ease-in;
  mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.06 6.94a1.5 1.5 0 1 0-2.12 2.12l2.12-2.12zM12 16l-1.06 1.06a1.5 1.5 0 0 0 2.12 0L12 16zm9.06-6.94a1.5 1.5 0 0 0-2.12-2.12l2.12 2.12zm-18.12 0l8 8 2.12-2.12-8-8-2.12 2.12zm10.12 8l8-8-2.12-2.12-8 8 2.12 2.12z'/%3E%3C/svg%3E");
  content: '';
}

.c-accordion-subitem__title:hover {
  color: var(--color-orange-hover);
  background: var(--color-black-light-hover);
}

.c-accordion-subitem__title:hover::after {
  background: var(--color-orange-hover);
}

#car-equipment:checked + .c-accordion__item,
#navigation-equipment:checked + .c-accordion__item,
#photography:checked + .c-accordion__item,
#general-equipment:checked + .c-accordion__item,
#tents:checked + .c-accordion__item,
#kitchenware:checked + .c-accordion__item,
#toiletries:checked + .c-accordion__item,
#firstaid:checked + .c-accordion__item {
  max-height: 4000px;
}

#car-equipment:checked + .c-accordion__item .c-accordion-item__title::after,
#navigation-equipment:checked + .c-accordion__item .c-accordion-item__title::after,
#photography:checked + .c-accordion__item .c-accordion-item__title::after,
#general-equipment:checked + .c-accordion__item .c-accordion-item__title::after,
#tents:checked + .c-accordion__item .c-accordion-item__title::after,
#kitchenware:checked + .c-accordion__item .c-accordion-item__title::after,
#toiletries:checked + .c-accordion__item .c-accordion-item__title::after,
#firstaid:checked + .c-accordion__item .c-accordion-item__title::after {
  transform: rotate(0deg);
}

#other-equipment:checked + .c-accordion__subitem,
#electrical-equipment:checked + .c-accordion__subitem,
#headlights:checked + .c-accordion__subitem,
#hilift-jack:checked + .c-accordion__subitem,
#tools:checked + .c-accordion__subitem,
#gps-tablet:checked + .c-accordion__subitem,
#gps:checked + .c-accordion__subitem,
#autotent:checked + .c-accordion__subitem,
#groundtent:checked + .c-accordion__subitem,
#kitchen-equipment:checked + .c-accordion__subitem,
#first-bag:checked + .c-accordion__subitem,
#second-bag:checked + .c-accordion__subitem,
#kitchenware-bag:checked + .c-accordion__subitem,
#cutlery-bag:checked + .c-accordion__subitem,
#glasses-bag:checked + .c-accordion__subitem,
#food-bag:checked + .c-accordion__subitem,
#bbq:checked + .c-accordion__subitem,
#washing-set:checked + .c-accordion__subitem,
#table-chairs:checked + .c-accordion__subitem,
#bedding:checked + .c-accordion__subitem {
  max-height: 2200px;
}

#other-equipment:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#electrical-equipment:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#headlights:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#hilift-jack:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#tools:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#gps-tablet:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#gps:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#autotent:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#groundtent:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#kitchen-equipment:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#first-bag:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#second-bag:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#kitchenware-bag:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#cutlery-bag:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#glasses-bag:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#food-bag:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#bbq:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#washing-set:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#table-chairs:checked + .c-accordion__subitem .c-accordion-subitem__title::after,
#bedding:checked + .c-accordion__subitem .c-accordion-subitem__title::after {
  transform: rotate(0deg);
}
