.c-recent-posts {
  display: none;

  grid-column: recent / right-gutter;
  align-self: start;

  width: 100%;
  margin-top: 1rem;
  padding: 2rem;

  background: var(--color-sand-light-dark);
  border: 1px solid var(--color-sand);
  border-radius: var(--border-radius--small);
  box-shadow: var(--shadow-light);

  @media (--desktop) {
    display: block;
  }
}

.c-recent-posts__title {
  margin-top: 0;

  font-weight: 500;
  font-size: 2rem;
  text-transform: uppercase;
}

.c-recent-posts__post > h3 {
  margin-top: 0;
  font-weight: 400;
}
