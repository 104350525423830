.c-car-equipment {
  display: grid;
  width: 100%;

  grid-template-columns: [content] 1fr;
  grid-template-rows: [car-layout] auto [equipmment] auto;

  row-gap: 2rem;
  column-gap: 3rem;

  grid-column: content;

  @media (--tablet) {
    grid-template-columns: [content] 1fr [car-layout] 1.5fr;
    grid-template-rows: auto;
  }

  @media (--laptop) {
    column-gap: 4rem;
  }

  @media (--desktop) {
    column-gap: 6rem;
  }
}
