.c-program-bt {
  margin: 0;
  padding: 0;
  list-style: none;

  & > * + * {
    margin-top: 2rem;

    @media (--mobile-m) {
      margin-top: 2.5rem;
    }

    @media (--tablet) {
      margin-top: 3rem;
    }
  }
}

.c-program__book {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.c-program__book > .c-btn {
  width: 100%;
  min-width: 230px;
  max-width: none;
  margin: 0 1rem;

  @media (--mobile-m) {
    margin: 0 1.5rem;
  }

  @media (--tablet) {
    width: initial;
  }
}
