.c-input {
  width: 100%;
  min-width: 100px;
  height: 40px;

  padding-left: 0.6rem;

  color: var(--color-black);
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  background: var(--color-sand-light);

  border: none;
  border-radius: var(--border-radius--small);

  box-shadow: var(--shadow-light);
}

.c-input:disabled {
  background: var(--color-white-dark);
  border: solid 1px var(--color-white-dark);
}

.c-input--center {
  text-align: center;
}
