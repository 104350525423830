:root {
  --hero-gradient:
    linear-gradient(
      180deg,
      #1c1c1c 0%,
      rgba(28, 28, 28, 0.9) 14.92%,
      rgba(28, 28, 28, 0.6) 46.96%,
      rgba(28, 28, 28, 0.6) 74.03%,
      rgba(28, 28, 28, 0.8) 86.74%,
      #1c1c1c 100%
    );
  --section-gradient:
    linear-gradient(
      0deg,
      rgba(217, 174, 139, 1) 0%,
      rgba(217, 174, 139, 0.9) 15%,
      rgba(217, 174, 139, 0.9) 85%,
      rgba(217, 174, 139, 1) 100%
    );
  --order-gradient:
    linear-gradient(
      180deg,
      #1c1c1c 8.01%,
      rgba(28, 28, 28, 0.85) 35.94%,
      rgba(28, 28, 28, 0.85) 84.9%,
      #1c1c1c 100%
    );
}
