.c-btn {
  display: block;
  min-width: 205px;
  max-width: 224px;
  height: 48px;
  color: var(--color-white);

  font-weight: 400;
  font-size: 1.125rem;
  line-height: 44px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  background: none;
  border-radius: var(--border-radius);

  transition: background-color 0.15s ease-in-out;
}

.c-btn:hover {
  color: var(--color-white);
}

.c-btn:active {
  box-shadow: none;
}

.c-btn--primary {
  border: 2px solid var(--color-orange);
}

.c-btn--secondary {
  border: 2px solid var(--color-green);
}

.c-btn--primary:hover {
  background: var(--color-orange);
}

.c-btn--secondary:hover {
  background: var(--color-green);
}

.c-btn:disabled {
  color: var(--color-disabled-dark);
  background-color: var(--color-disabled-light);
  border-color: var(--color-disabled-light);
}

.c-btn:disabled:hover {
  background-color: var(--color-disabled-light);
  border-color: var(--color-disabled-light);
}

.c-btn:disabled:active {
  box-shadow: var(--shadow-normal);
}

.c-btn--default {
  color: var(--color-black);
  border: solid 2px var(--color-black);
  box-shadow: none;
}

.c-btn__arrow-left {
  min-width: 80px;
  max-width: 80px;
  font-size: 0;

  @media (--tablet) {
    min-width: 205px;
    max-width: 224px;
    padding-left: 30px;
    font-size: 1.125rem;
  }
}

.c-btn__arrow-left::before {
  position: absolute;
  content: '';
  width: 30px;
  height: 24px;
  margin-top: 11px;
  margin-left: -16px;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='24' viewBox='0 0 30 24' fill='%23f1f1f1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.4393 21.0607C22.0251 21.6464 22.9749 21.6464 23.5607 21.0607C24.1464 20.4749 24.1464 19.5251 23.5607 18.9393L16.6213 12L23.5607 5.06066C24.1464 4.47487 24.1464 3.52513 23.5607 2.93934C22.9749 2.35355 22.0251 2.35355 21.4393 2.93934L13.4393 10.9393C12.8536 11.5251 12.8536 12.4749 13.4393 13.0607L21.4393 21.0607ZM13.4393 21.0607C14.0251 21.6464 14.9749 21.6464 15.5607 21.0607C16.1464 20.4749 16.1464 19.5251 15.5607 18.9393L8.62132 12L15.5607 5.06066C16.1464 4.47487 16.1464 3.52513 15.5607 2.93934C14.9749 2.35355 14.0251 2.35355 13.4393 2.93934L5.43934 10.9393C4.85355 11.5251 4.85355 12.4749 5.43934 13.0607L13.4393 21.0607Z'/%3E%3C/svg%3E%0A");

  @media (--tablet) {
    margin-left: -35px;
  }
}

.c-btn__arrow-right {
  min-width: 80px;
  max-width: 80px;
  font-size: 0;

  @media (--tablet) {
    min-width: 205px;
    max-width: 224px;
    padding-right: 30px;
    font-size: 1.125rem;
  }
}

.c-btn__arrow-right::after {
  position: absolute;
  content: '';
  width: 30px;
  height: 24px;
  margin-top: 11px;
  margin-left: -13px;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='24' viewBox='0 0 30 24' fill='%23f1f1f1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.56066 2.93934C7.97487 2.35355 7.02513 2.35355 6.43934 2.93934C5.85355 3.52513 5.85355 4.47487 6.43934 5.06066L13.3787 12L6.43934 18.9393C5.85355 19.5251 5.85355 20.4749 6.43934 21.0607C7.02513 21.6464 7.97487 21.6464 8.56066 21.0607L16.5607 13.0607C17.1464 12.4749 17.1464 11.5251 16.5607 10.9393L8.56066 2.93934ZM16.5607 2.93934C15.9749 2.35355 15.0251 2.35355 14.4393 2.93934C13.8536 3.52513 13.8536 4.47487 14.4393 5.06066L21.3787 12L14.4393 18.9393C13.8536 19.5251 13.8536 20.4749 14.4393 21.0607C15.0251 21.6464 15.9749 21.6464 16.5607 21.0607L24.5607 13.0607C25.1464 12.4749 25.1464 11.5251 24.5607 10.9393L16.5607 2.93934Z'/%3E%3C/svg%3E");

  @media (--tablet) {
    margin-left: 6px;
  }
}
