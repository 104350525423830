.c-program {
  margin: 0;
  padding-left: 0;

  overflow: hidden;

  list-style-type: none;

  background: var(--color-sand-light);

  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
}

.c-program > li {
  padding: 1rem;

  @media (--mobile-m) {
    padding: 1.5rem;
  }

  @media (--tablet) {
    padding: 1.5rem;
  }

  @media (--laptop) {
    padding: 2rem;
  }
}

.c-program > li:nth-child(odd) {
  background: var(--color-sand-light-dark);
}

.c-program > li:nth-child(even) > .c-day {
  flex-direction: row-reverse;
}

.c-program__book {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.c-program__book > .c-btn {
  width: 100%;
  min-width: 230px;
  max-width: none;
  margin: 0 1rem;

  @media (--mobile-m) {
    margin: 0 1.5rem;
  }

  @media (--tablet) {
    width: initial;
  }
}
