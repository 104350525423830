:root {
  --color-black: #503429;
  --color-black-hover: #6a4536;
  --color-black-light: #774d3d;
  --color-black-light-hover: #9e6751;
  --color-white: #f1f1f1;
  --color-sand: #d9ae8b;
  --color-sand-80: rgba(217, 174, 139, 0.8);
  --color-sand-85: rgba(217, 174, 139, 0.9);
  --color-sand-light: #e8cdb7;
  --color-sand-light-80: rgba(232, 205, 183, 0.8);
  --color-sand-light-85: rgba(232, 205, 183, 0.85);
  --color-sand-light-dark: #dfc0a4;
  --color-orange: #ed7c2d;
  --color-orange-hover: #f19657;
  --color-green: #606838 !important;
  --color-green-80: rgba(96, 104, 56, 0.8);
  --color-green-hover: #808660;
  --color-disabled-light: #e0e0e0;
  --color-disabled-dark: #8b8b8b;
}

.u-color--orange {
  color: var(--color-orange);
}
