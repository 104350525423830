.c-gallery-wrapper {
  position: relative;

  grid-row: text / footer;

  padding-top: 3rem;
}

.c-gallery-wrapper > * + * {
  margin-top: 2rem;

  @media (--laptop) {
    margin-top: 3rem;
  }
}

.c-gallery {
  position: relative;

  grid-row: text / footer;

  width: 100%;
  height: 700px;

  /* overflow: visible; */
}

.c-gallery--left {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: [main] 3fr [secondary] 2fr;
  row-gap: 1rem;
  column-gap: 1rem;

  @media (--laptop) {
    grid-template-columns: [main] 3fr [secondary] 2fr;
    grid-template-rows: 1fr 1fr;

    row-gap: 2rem;
    column-gap: 2rem;
  }
}

.c-gallery--right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: [main] 3fr [secondary] 2fr;
  row-gap: 1rem;
  column-gap: 1rem;

  @media (--laptop) {
    grid-template-columns: [secondary] 2fr [main] 3fr;
    grid-template-rows: 1fr 1fr;

    row-gap: 2rem;
    column-gap: 2rem;
  }
}

.c-gallery a {
  display: block;
}
