@custom-media --mobile-s screen and (min-width: 320px);
@custom-media --mobile-m screen and (min-width: 375px);
@custom-media --mobile-l screen and (min-width: 425px);
@custom-media --mobile-s-x2 screen and (min-width: 320px) and (min-resolution: 2dppx);
@custom-media --mobile-m-x2 screen and (min-width: 375px) and (min-resolution: 2dppx);
@custom-media --mobile-l-x2 screen and (min-width: 425px) and (min-resolution: 2dppx);
@custom-media --hero screen and (min-width: 504px);
@custom-media --tablet screen and (min-width: 768px);
@custom-media --tablet-3_4 screen and (min-width: 768px) and (aspect-ratio 3/4);
@custom-media --laptop screen and (min-width: 1024px);
@custom-media --laptop-3_4 screen and (min-aspect-ratio: 3/4); /* 1024px */
@custom-media --table-x2 screen and (min-width: 768px) and (min-resolution: 2dppx);
@custom-media --laptop-x2 screen and (min-width: 1024px) and (min-resolution: 2dppx);
@custom-media --desktop-s screen and (min-width: 1160px);
@custom-media --wrapper screen and (min-width: 1240px);
@custom-media --desktop screen and (min-width: 1520px);
@custom-media --widescreen screen and (min-width: 1920px);
@custom-media --bg-m screen and (min-width: 608px);
@custom-media --bg-l screen and (min-width: 1024px);
