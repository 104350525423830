.c-manager {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (--mobile-m) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.c-manager__info > p {
  display: block;
  font-weight: 600;
  font-size: 1.125rem;
}

.c-manager__info > a {
  display: block;
  color: var(--color-white);
}

.c-manager__info > a:hover {
  color: var(--color-orange);
}

.c-manager__foto {
  flex: auto 0 0;
  width: 75px;
  height: 75px;

  margin-bottom: 1rem;

  /* background: var(--color-sand-light); */
  background-image: url(../img/avatar.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 120%;
  border: 3px solid var(--color-sand);
  border-radius: 40px;
  box-shadow: var(--shadow-light);

  @media (--mobile-m) {
    margin-right: 1rem;
    margin-bottom: 0;
  }
}
