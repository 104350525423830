.c-bg__h-zambia {
  @mixin bg-hero zambia, top;
}

.c-bg__s-zambia {
  @mixin bg-section zambia, дуае;
}

.c-bg__s-zambia-s01 {
  @mixin bg-section zambia-s01, right;
}

.c-bg__s-zambia-s02 {
  @mixin bg-section zambia-s02, left;
}

.c-bg__s-zambia01 {
  @mixin bg-section zambia01, right;
}

.c-bg__s-zambia02 {
  @mixin bg-section zambia02, left;
}

.c-bg__s-zambia03 {
  @mixin bg-section zambia03, right;
}
