.c-bg__h-group-tour {
  @mixin bg-hero group, center;
}

.c-bg__s-group {
  @mixin bg-section group, right;
}

.c-bg__h-group01 {
  @mixin bg-hero group01, top;
}

.c-bg__h-group02 {
  @mixin bg-hero group02, top;
}

.c-bg__s-group01 {
  @mixin bg-section group01, right;
}

.c-bg__s-group02 {
  @mixin bg-section group02, right;
}

.c-bg__s-group-tour {
  @mixin bg-section group-tour, right;
}

.c-bg__s-group-tour01 {
  @mixin bg-section group-tour01, left;
}

.c-bg__s-group-tour02 {
  @mixin bg-section group-tour02, right;
}

.c-bg__s-group-tour03 {
  @mixin bg-section group-tour03, left;
}
