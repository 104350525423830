.c-bg__h-group-u {
  @mixin bg-hero group-u;
}

/* .c-bg__hero-group-u {
  background-image: url(../img/hero-group-u-1024px.jpg);
  background-position: center top;

  @media (--laptop) {
    background-image: url(../img/hero-group-u-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-group-u-1920px.jpg);
  }
} */

.c-bg__group-u-desc {
  background-image: url(../img/desc-group-u-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/desc-group-u-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/desc-group-u-1920px.jpg);
  }
}

.c-bg__group-u-spec {
  background-image: url(../img/spec-group-u-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/spec-group-u-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/spec-group-u-1920px.jpg);
  }
}

.c-bg__footer-group-u {
  background-image: url(../img/section-footer-group-u-1024px.jpg);
  background-position: center top;

  @media (--laptop) {
    background-image: url(../img/section-footer-group-u-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-footer-group-u-1920px.jpg);
  }
}

.c-bg__hero-group-u-equipment {
  background-image: url(../img/hero-group-u-equipment-1024px.jpg);
  background-position: center bottom;

  @media (--laptop) {
    background-image: url(../img/hero-group-u-equipment-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-group-u-equipment-1920px.jpg);
  }
}

.c-bg__hero-group-u-equipment::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  content: '';

  @media (--tablet) {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.c-bg__gallery-group-u-01 {
  background-image: url(../img/gallery-bg-group-u-001-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-u-001-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-u-001-1920px.jpg);
  }
}

.c-bg__gallery-group-u-01::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}

.c-bg__gallery-group-u-02 {
  background-image: url(../img/gallery-bg-group-u-002-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-u-002-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-u-002-1920px.jpg);
  }
}

.c-bg__gallery-group-u-02::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}

.c-bg__gallery-group-u-03 {
  background-image: url(../img/gallery-bg-group-u-003-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-u-003-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-u-003-1920px.jpg);
  }
}

.c-bg__gallery-group-u-03::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}
