.c-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  display: block;
  width: 100%;
  height: 100%;

  grid-column: 1 / -1;
  grid-row: 1 / -1;

  @media (--tablet) {
    display: block;
  }
}

.c-video video {
  width: 100%;
  height: auto;

  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }

  @media (max-aspect-ratio: 16/9) {
    width: auto;
    height: 100%;
  }
}
