.o-section {
  grid-template-rows: [header] 2rem [text] auto [cards-title] auto [cards] auto [footer] 0;

  @media (--mobile-m) {
    grid-template-rows: [header] 3rem [text] auto [cards-title] auto [cards] auto [footer] 0;
  }

  @media (--tablet) {
    grid-template-rows: [header] 3rem [text] auto [cards-title] 7rem [cards] auto [footer] 0;
  }

  @media (--laptop) {
    min-height: 900px;
    margin-bottom: 4rem;
    grid-template-rows: [header] 7rem [text] auto [cards-title] 7rem [cards] auto [footer] 7rem;
  }
}
