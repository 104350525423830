.c-scroll-icon {
  position: absolute;
  display: block;

  width: 35px;

  grid-row: footer;
  grid-column: content / right-gutter;
  justify-self: center;

  @media (--tablet) {
    bottom: 0;
  }
}

.c-scroll-icon--light {
  stroke: var(--color-sand-light);
}

.c-scroll-icon--dark {
  stroke: var(--color-black);
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(5px);
  }
}

svg #wheel {
  transform: translate3d(0, 0, 0);
  animation: scroll ease 2s infinite;
  stroke-width: 3px;
  will-change: transform;
}

svg #mouse {
  box-shadow: var(--shadow-light);
  fill: none;

  stroke-width: 2px;
}
