.c-modal {
  z-index: 1000;
}

.c-modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}

.c-modal__container {
  max-width: 1024px;
  max-height: 100vh;
  padding: 7rem 1rem 3rem;
  overflow-y: auto;

  background-color: var(--color-sand);
  border-radius: var(--border-radius);

  @media (--tablet) {
    padding: 4rem 2rem 2rem;
  }

  @media (--laptop) {
    padding: 2rem;
  }
}

.c-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.c-modal__title {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--color-black);
  font-weight: 500;
  font-size: 1.65rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.2;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--mobile-m) {
    font-size: 2rem;
  }

  @media (--mobile-l) {
    font-size: 2.25rem;
  }

  @media (--tablet) {
    /* margin-bottom: 2rem; */
  }

  @media (--laptop) {
    font-size: 2.5rem;
  }
}

.c-modal__close {
  background: transparent;
  border: 0;
}

.c-modal__close .c-clsbtn {
  position: inherit;
}

.c-modal__content {
  color: var(--color-black);
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.c-modal-slide {
  display: none;
}

.c-modal-slide.is-open {
  display: block;
}

.c-modal-slide .c-modal__container,
.c-modal-slide .c-modal__overlay {
  will-change: transform;
}

.c-modal-slide[aria-hidden='false'] .c-modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.c-modal-slide[aria-hidden='false'] .c-modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.c-modal-slide[aria-hidden='true'] .c-modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.c-modal-slide[aria-hidden='true'] .c-modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
