.c-footnote {
  position: relative;
  margin-top: 0;

  & > p {
    color: var(--color-black);
    font-size: 0.8rem;
  }
}

.c-footnote::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%; /* or 100px */
  height: 1px;
  border-top: 1px solid var(--color-black);
  content: '';
}
