.c-form-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;

  @media (--laptop) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.c-form-section__side {
  flex: 0 1 648px;
  max-width: 648px;

  @media (--laptop) {
    flex-grow: 0;
    max-width: 480px;
  }
}

.c-form-section__title {
  margin: 0 0 0.5rem;

  font-weight: 600;
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
  text-transform: uppercase;

  @media (--tablet) {
    font-size: 1.5rem;
  }
}

.c-form-section__description {
  margin: 0 0 1rem;

  font-size: 0.75rem;
  font-style: italic;
  line-height: 1.4;
  letter-spacing: 0.5px;

  @media (--tablet) {
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
  }
}

/* .c-form-section__description {
  flex: 1 1 288px;
}

.c-form-section__controls {
  flex: 1 1 288px;
} */
