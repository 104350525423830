.o-anchor {
  position: relative;
  top: -75px;
  display: block;
  visibility: hidden;

  @media (--tablet) {
    top: -100px;
  }
}
