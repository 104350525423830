/* @import url('https://fonts.googleapis.com/css?family=Caveat&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&display=swap&subset=cyrillic,cyrillic-ext');

@font-face {
  font-weight: 600;
  font-family: 'Open Sans';
  font-style: italic;
  font-display: auto;
  src: url('../fonts/OpenSans-SemiBoldItalic.eot');
  src:
    local('Open Sans SemiBold Italic'),
    local('OpenSans-SemiBoldItalic'),
    url('../fonts/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
    url('../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-weight: 300;
  font-family: 'Open Sans';
  font-style: italic;
  font-display: auto;
  src: url('../fonts/OpenSans-LightItalic.eot');
  src:
    local('Open Sans Light Italic'),
    local('OpenSans-LightItalic'),
    url('../fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-LightItalic.woff') format('woff'),
    url('../fonts/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-weight: bold;
  font-family: 'Open Sans';
  font-style: normal;
  font-display: auto;
  src: url('../fonts/OpenSans-Bold.eot');
  src:
    local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Bold.woff') format('woff'),
    url('../fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-weight: 800;
  font-family: 'Open Sans';
  font-style: italic;
  font-display: auto;
  src: url('../fonts/OpenSans-ExtraBoldItalic.eot');
  src:
    local('Open Sans ExtraBold Italic'),
    local('OpenSans-ExtraBoldItalic'),
    url('../fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
    url('../fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-weight: 300;
  font-family: 'Open Sans';
  font-style: normal;
  font-display: auto;
  src: url('../fonts/OpenSans-Light.eot');
  src:
    local('Open Sans Light'),
    local('OpenSans-Light'),
    url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Light.woff') format('woff'),
    url('../fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-weight: normal;
  font-family: 'Open Sans';
  font-style: italic;
  font-display: auto;
  src: url('../fonts/OpenSans-Italic.eot');
  src:
    local('Open Sans Italic'),
    local('OpenSans-Italic'),
    url('../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Italic.woff') format('woff'),
    url('../fonts/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-weight: normal;
  font-family: 'Open Sans';
  font-style: normal;
  font-display: auto;
  src: url('../fonts/OpenSans-Regular.eot');
  src:
    local('Open Sans Regular'),
    local('OpenSans-Regular'),
    url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Regular.woff') format('woff'),
    url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 600;
  font-family: 'Open Sans';
  font-style: normal;
  font-display: auto;
  src: url('../fonts/OpenSans-SemiBold.eot');
  src:
    local('Open Sans SemiBold'),
    local('OpenSans-SemiBold'),
    url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-SemiBold.woff') format('woff'),
    url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-weight: 800;
  font-family: 'Open Sans';
  font-style: normal;
  font-display: auto;
  src: url('../fonts/OpenSans-ExtraBold.eot');
  src:
    local('Open Sans ExtraBold'),
    local('OpenSans-ExtraBold'),
    url('../fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-ExtraBold.woff') format('woff'),
    url('../fonts/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-weight: bold;
  font-family: 'Open Sans';
  font-style: italic;
  font-display: auto;
  src: url('../fonts/OpenSans-BoldItalic.eot');
  src:
    local('Open Sans Bold Italic'),
    local('OpenSans-BoldItalic'),
    url('../fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-BoldItalic.woff') format('woff'),
    url('../fonts/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-weight: 600;
  font-family: 'Oswald';
  font-style: normal;
  src: url('../fonts/Oswald-SemiBold.eot');
  src:
    local('Oswald SemiBold'),
    local('Oswald-SemiBold'),
    url('../fonts/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Oswald-SemiBold.woff') format('woff'),
    url('../fonts/Oswald-SemiBold.ttf') format('truetype');
}

@font-face {
  font-weight: 200;
  font-family: 'Oswald';
  font-style: normal;
  src: url('../fonts/Oswald-ExtraLight.eot');
  src:
    local('Oswald ExtraLight'),
    local('Oswald-ExtraLight'),
    url('../fonts/Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Oswald-ExtraLight.woff') format('woff'),
    url('../fonts/Oswald-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-weight: 300;
  font-family: 'Oswald';
  font-style: normal;
  src: url('../fonts/Oswald-Light.eot');
  src:
    local('Oswald Light'),
    local('Oswald-Light'),
    url('../fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Oswald-Light.woff') format('woff'),
    url('../fonts/Oswald-Light.ttf') format('truetype');
}

@font-face {
  font-weight: bold;
  font-family: 'Oswald';
  font-style: normal;
  src: url('../fonts/Oswald-Bold.eot');
  src:
    local('Oswald Bold'),
    local('Oswald-Bold'),
    url('../fonts/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Oswald-Bold.woff') format('woff'),
    url('../fonts/Oswald-Bold.ttf') format('truetype');
}

@font-face {
  font-weight: normal;
  font-family: 'Oswald';
  font-style: normal;
  src: url('../fonts/Oswald-Regular.eot');
  src:
    local('Oswald Regular'),
    local('Oswald-Regular'),
    url('../fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Oswald-Regular.woff') format('woff'),
    url('../fonts/Oswald-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: 'Oswald';
  font-style: normal;
  src: url('../fonts/Oswald-Medium.eot');
  src:
    local('Oswald Medium'),
    local('Oswald-Medium'),
    url('../fonts/Oswald-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Oswald-Medium.woff') format('woff'),
    url('../fonts/Oswald-Medium.ttf') format('truetype');
}
