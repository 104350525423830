.c-route-info {
  margin-bottom: 2rem;
}

.c-route-info__text {
  margin: 0 0 1rem;
  line-height: 1;
}

.c-route-info__text span {
  font-weight: 600;
}
