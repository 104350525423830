.c-spec__btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  max-width: 500px;

  @media (--desktop) {
    max-width: 590px;
  }

  /* justify-content: center; */
}

.c-spec__btns > * + * {
  margin-top: 1.5rem;

  @media (min-width: 1427px) {
    margin-top: 0;
    margin-left: 1.5rem;
  }
}

.c-spec__btns > .c-btn {
  flex: 1 1 288px;
  max-width: none;
  max-height: none;

  @media (min-width: 1427px) {
    flex: 1 0 205px;
    max-width: 205px;
  }
}
