.c-distance {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-distance__icon {
  width: 48px;
  height: 48px;

  @media (--tablet) {
    width: 64px;
    height: 64px;
  }
}

.c-distance > .c-distance__icon:first-child {
  margin-bottom: 2rem;
}

.c-distance > .c-distance__icon:last-child {
  margin-top: 2rem;
}

.c-distance__km {
  display: flex;
  align-items: center;
}

.c-distance__km p {
  margin-right: 1rem;

  font-weight: 500;
  font-size: 1.7rem;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1.2px;

  /* text-transform: uppercase; */
  text-shadow: var(--shadow-text);

  @media (--tablet) {
    font-size: 2rem;
  }
}
