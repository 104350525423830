.c-bg {
  position: relative;
  z-index: 1;

  width: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.c-bg::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.15);
  content: '';
}

.c-bg--left::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  background: var(--section-gradient);

  content: '';

  @media (--laptop) {
    width: 48.8%;
  }

  @media (--desktop) {
    width: 46%;
  }
}

.c-bg--right::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--section-gradient);

  content: '';

  @media (--laptop) {
    left: 51.2%;
  }

  @media (--desktop) {
    left: 54%;
  }
}

.c-bg__s-individual {
  @mixin bg-section individual, left;
}

.c-bg__s-cars {
  @mixin bg-section cars, right;
}

.c-bg__h-group-equipment {
  @mixin bg-hero group-equipment;
}

.c-bg__compare {
  background: var(--color-sand);
}

.c-bg__footer {
  background-image: url(../img/section-footer-1024px.jpg);
  background-position: center top;

  @media (--laptop) {
    background-image: url(../img/section-footer-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-footer-1920px.jpg);
  }
}
