.u-visible--on-mobile {
  display: block;

  @media (--laptop) {
    display: none;
  }
}

.u-visible--on-tablet {
  display: block;

  @media (--laptop) {
    display: none;
  }
}

.u-visible--on-desktop {
  display: none;

  @media (--laptop) {
    display: block;
  }
}

.u-rotate-180 {
  transform: rotate(180deg);
}

.u-rotate-0 {
  transform: rotate(0deg);
}

.u-padding-bottom-2rem {
  padding-bottom: 2rem;
}

.u-padding-bottom-3rem {
  padding-bottom: 3rem;

  @media (--laptop) {
    padding-bottom: 0;
  }
}

.c-sticky {
  position: sticky;
  top: 200px;
}

.u-cursor--normal {
  cursor: default;
}
