.c-car-layout {
  grid-column: content;
  grid-row: car-layout;
  width: 100%;

  padding: 2rem;
  background: var(--color-sand-light);

  box-shadow: var(--shadow-light);

  @media (--tablet) {
    margin-top: 41px;

    grid-column: car-layout;
    grid-row: 1 / -1;
  }
}
