.c-bg__h-mozambique {
  @mixin bg-hero mozambique, bottom;
}

.c-bg__s-mozambique {
  @mixin bg-section mozambique, left;
}

.c-bg__s-mozambique-s01 {
  @mixin bg-section mozambique-s01, right;
}

.c-bg__s-mozambique-s02 {
  @mixin bg-section mozambique-s02, left;
}

.c-bg__s-mozambique01 {
  @mixin bg-section mozambique01, right;
}

.c-bg__s-mozambique02 {
  @mixin bg-section mozambique02, left;
}

.c-bg__s-mozambique03 {
  @mixin bg-section mozambique03, right;
}
