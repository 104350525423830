.c-post__hero {
  width: 100%;
  text-align: center;

  @media (--laptop) {
    width: 80%;
    text-align: left;
  }

  @media (--desktop) {
    width: 70%;
  }

  @media (--widescreen) {
    width: 50%;
  }
}

.c-post__title {
  width: 100%;
  margin-bottom: 2rem;

  font-size: 1.5rem;
  line-height: 1.1;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--mobile-m) {
    font-size: 1.8rem;
  }

  @media (--tablet) {
    font-size: 2.5rem;
  }

  @media (--laptop) {
    margin-bottom: 2.5rem;
    font-size: 3rem;
  }

  @media (--desktop) {
    margin-bottom: 3rem;
    font-size: 4rem;
  }
}

.c-post__author {
  width: 100%;
  margin: 0 auto 0.5rem;

  color: var(--color-orange);
  font-weight: 500;
  font-size: 1.3rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.1;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--desktop) {
    font-size: 2rem;
  }
}

.c-post__date {
  width: 100%;
  margin: 0 auto 0.5rem;

  color: var(--color-orange);
  font-weight: 500;
  font-size: 1.3rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.1;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--desktop) {
    font-size: 2rem;
  }
}

/* .c-post__date {
  width: 100%;
  margin-bottom: 2rem;
} */

.c-post__content {
  grid-column: content / right-gutter;

  @media (--desktop) {
    padding-right: 6rem;
    grid-column: content / recent;
  }
}

.c-post__content > h2 {
  margin-top: 3rem;
}

.c-post__content + h2 {
  margin-top: 0;
}

.c-post__content > p + p {
  margin-top: 1rem;
}

.c-post__content > p:empty + h2 {
  margin-top: 0;
}

.c-post__content img {
  width: 100%;

  margin-bottom: 2rem;

  border: 1px solid var(--color-sand);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-normal);

  @media (--tablet) {
    margin-bottom: 3rem;
  }

  @media (--laptop) {
    margin-bottom: 4rem;
  }
}

.c-post__content img:first-of-type {
  margin-top: 2rem;

  @media (--tablet) {
    margin-top: 2rem;
  }

  @media (--laptop) {
    margin-top: 3rem;
  }
}
