.o-content {
  position: relative;
  display: grid;

  width: 100%;

  overflow: hidden;

  grid-template-columns: [left-gutter] 1rem [content] minmax(0, 1fr) [right-gutter] 1rem;

  @media (--mobile-m) {
    grid-template-columns: [left-gutter] 1.5rem [content] minmax(0, 1fr) [right-gutter] 1.5rem;
  }

  @media (--tablet) {
    grid-template-columns: [left-gutter] 3rem [content] 10fr [right-gutter] 3rem;
  }

  /* @media (--laptop) {
    grid-template-columns: [left-gutter] 2fr [content] 10fr [right-gutter] 2fr;
  } */

  @media (--desktop-s) {
    grid-template-columns: [left-gutter] 1.5fr [content] 10fr [right-gutter] 1.5fr;
  }

  @media (--desktop) {
    grid-template-columns: [left-gutter] 2fr [content] 10fr [right-gutter] 2fr;
  }

  @media (--widescreen) {
    grid-template-columns: [left-gutter] 3fr [content] 10fr [right-gutter] 3fr;
  }
}

.o-content--no-gutter {
  grid-template-columns: [left-gutter] 0 [content] minmax(0, 1fr) [right-gutter] 0;

  @media (--tablet) {
    grid-template-columns: [left-gutter] 3rem [content] 10fr [right-gutter] 3rem;
  }

  /* @media (--laptop) {
    grid-template-columns: [left-gutter] 2fr [content] 10fr [right-gutter] 2fr;
  } */

  @media (--desktop-s) {
    grid-template-columns: [left-gutter] 1.5fr [content] 10fr [right-gutter] 1.5fr;
  }

  @media (--desktop) {
    grid-template-columns: [left-gutter] 2fr [content] 10fr [right-gutter] 2fr;
  }

  @media (--widescreen) {
    grid-template-columns: [left-gutter] 3fr [content] 10fr [right-gutter] 3fr;
  }
}

.o-content > * {
  grid-column: content / right-gutter;
}

.o-content > .o-content--full-width {
  align-self: start;
  height: 305px;
  overflow-y: hidden;
  grid-column: left-gutter / -1;

  @media (--tablet) {
    align-self: stretch;
    height: 100%;
    overflow: visible;
    grid-column: content / right-gutter;
  }
}

.o-content--full-height {
  padding: 2rem 0 4rem;

  @media (--laptop) {
    padding: 6rem 0 6rem;
  }
}
