.c-checkbox {
  position: relative;
  margin: 1em 0;
  text-align: left;
}

.c-checkbox.c-checkbox-inline {
  display: inline-block;
}

.c-checkbox label {
  display: inline;
  clear: both;
  padding-left: 1px;
  line-height: 1.25em;
  cursor: pointer;
}

.c-checkbox label:not(:empty) {
  padding-left: 0.75em;
}

.c-checkbox label::before,
.c-checkbox label::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.c-checkbox label::before {
  width: 1.25em;
  height: 1.25em;
  background: var(--color-white);
  border: 2px solid var(--color-black); /* rgba(0, 0, 0, 0.54); */
  border-radius: 0.125em;
  cursor: pointer;
  transition: background 0.25s;
}

.c-checkbox input[type='checkbox'] {
  display: block;
  float: left;
  width: 1.25em;
  margin: 0;
  font-size: inherit;
  outline: 0;
  visibility: hidden;
}

.c-checkbox input[type='checkbox']:checked + label::before {
  background: var(--color-black);
  border: none;
}

.c-checkbox input[type='checkbox']:checked + label::after {
  width: 0.75em;
  height: 0.375em;
  border: 0.125em solid #fff;
  border-top-style: none;
  border-right-style: none;
  transform: translate(0.25em, 0.3365384615em) rotate(-45deg);
}

.c-checkbox input[type='checkbox']:disabled + label::before {
  border-color: rgba(0, 0, 0, 0.26);
}

.c-checkbox input[type='checkbox']:disabled:checked + label::before {
  background: rgba(0, 0, 0, 0.26);
}
