.c-vmenu {
  width: 100%;
  margin: 70px 0 0 0;
  padding: 0;

  list-style-type: none;
}

.c-vmenu__item {
  position: relative;
  display: flex;
  flex-direction: column;

  cursor: pointer;

  transition: all 0.5s ease-in-out;
}

.c-vmenu__item-link {
  position: relative;
  z-index: 1;

  display: block;

  width: 100%;

  margin-top: 0;
  margin-bottom: 0;
  padding: 1rem 1.5rem;

  color: var(--color-black);

  font-weight: 400;
  font-size: 1.125rem;
  font-size: 1.2rem;

  font-family: 'Oswald', sans-serif;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  transition: all 0.25s ease-in;
}

.c-vmenu__item-link:hover {
  color: var(--color-sand-light);
  background-color: var(--color-black);
}

.c-vmenu__item-link:active {
  color: var(--color-sand-light);
  background-color: var(--color-black);
}

#menu-tours,
#menu-cars {
  display: none;
}

#menu-tours + .c-vmenu__item,
#menu-cars + .c-vmenu__item {
  height: 60px;
  overflow: hidden;
}

#menu-tours:checked + .c-vmenu__item {
  height: 480px;
}

#menu-cars:checked + .c-vmenu__item {
  height: 300px;
}

.c-submenu {
  position: relative;
  transition: all 0.25s ease-in;
}

.c-submenu__title {
  display: block;
  width: 100%;
  padding: 1rem 1.5rem;
  color: var(--color-black);

  font-weight: 400;
  font-size: 1.125rem;
  font-size: 1.2rem;

  font-family: 'Oswald', sans-serif;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.25s ease-in;
}

.c-submenu__title::after {
  position: absolute;
  width: 19px;
  height: 19px;

  margin: 5px 0 0 4px;

  background-color: var(--color-black);
  mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.06 6.94a1.5 1.5 0 1 0-2.12 2.12l2.12-2.12zM12 16l-1.06 1.06a1.5 1.5 0 0 0 2.12 0L12 16zm9.06-6.94a1.5 1.5 0 0 0-2.12-2.12l2.12 2.12zm-18.12 0l8 8 2.12-2.12-8-8-2.12 2.12zm10.12 8l8-8-2.12-2.12-8 8 2.12 2.12z'/%3E%3C/svg%3E");

  transform: rotate(-90deg);
  transition: all 0.25s ease-in;
  content: '';
}

.c-submenu__title:hover {
  color: var(--color-sand-light);
  background: var(--color-black);
}

.c-submenu__title:hover::after {
  background-color: var(--color-sand-light);
}

#menu-tours:checked + .c-vmenu__item .c-submenu__title::after {
  transform: rotate(0deg);
}

#menu-cars:checked + .c-vmenu__item .c-submenu__title::after {
  transform: rotate(0deg);
}

.c-submenu__link {
  position: relative;
  z-index: 1;

  display: block;

  width: 100%;

  margin-top: 0;
  margin-bottom: 0;
  padding: 1rem 1.5rem;

  color: var(--color-black);

  font-weight: 400;
  font-size: 1.125rem;
  font-size: 1.2rem;

  font-family: 'Oswald', sans-serif;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background: var(--color-sand-light);

  transition: all 0.25s ease-in;
}

.c-submenu__link:hover {
  color: var(--color-sand-light);
  background: var(--color-black);
}
