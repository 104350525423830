.c-bg__h-contacts {
  @mixin bg-hero contacts;
}

.c-bg__contacts {
  background-image: url(../img/section-contacts-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/section-contacts-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-contacts-1920px.jpg);
  }
}
