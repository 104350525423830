.c-equipment-list {
  padding-left: 0;
  list-style-type: none;
}

.c-equipment-list > li {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  font-size: 0.875rem;
}

.c-equipment-list > li:nth-child(odd) {
  background: var(--color-sand-light);
}

.c-equipment-list > li:nth-child(even) {
  background: var(--color-sand-light-dark);
}

.c-equipment-list > li > span:first-child {
  padding-right: 1rem;
}

.c-equipment-list > li > span:last-child {
  align-self: center;
  width: 60px;
  text-align: right;
}
