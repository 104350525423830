/*
  South Africa heros
*/
.c-bg__h-southafrica {
  @mixin bg-hero southafrica;
}

.c-bg__s-southafrica {
  @mixin bg-section southafrica, right;
}

.c-bg__s-southafrica-s01 {
  @mixin bg-section southafrica-s01, right;
}

.c-bg__s-southafrica-s02 {
  @mixin bg-section southafrica-s02, left;
}

.c-bg__s-southafrica01 {
  @mixin bg-section southafrica01, right;
}

.c-bg__s-southafrica02 {
  @mixin bg-section southafrica02, left;
}

.c-bg__s-southafrica03 {
  @mixin bg-section southafrica03, right;
}
