.c-vctrls {
  z-index: 1;
  display: grid;

  align-self: end;

  column-gap: 1rem;

  grid-column: content / right-gutter;
  grid-row: cards / footer;

  grid-template-columns: [play] 32px [volume] 32px;
  grid-template-rows: 32px;
  justify-self: end;
}

.c-vctrl {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.c-vctrl > path {
  fill: var(--color-white);
}

.c-vctrl__volume-on {
  display: none;
  grid-column: volume / -1;
  grid-row: 1 / -1;
}

.c-vctrl__volume-off {
  display: none;
  grid-column: volume / -1;
  grid-row: 1 / -1;
}

.c-vctrl__play {
  display: none;
  grid-column: play / volume;
  grid-row: 1 / -1;
}

.c-vctrl__pause {
  display: none;
  grid-column: play / volume;
  grid-row: 1 / -1;
}
