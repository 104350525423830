.c-blogpost {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 3rem;

  @media (--tablet) {
    margin-bottom: 4rem;
  }

  @media (--desktop) {
    width: 50%;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}

.c-blogpost__title {
  margin-top: 0;
  font-size: 1.5rem;
  line-height: 1.1;
  text-shadow: var(--shadow-text);

  @media (--mobile-m) {
    font-size: 1.8rem;
  }

  @media (--tablet) {
    font-size: 2rem;
  }
}

.c-blogpost__info {
  display: flex;
}

.c-blogpost__separator {
  margin: 0 0.5rem;
}

.c-blogpost__content {
  /* display: flex; */
}

.c-blogpost__title-img {
  width: 100%;
  height: 208px;

  margin-bottom: 1rem;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  /* border: 1px solid var(--color-sand); */
  border-radius: var(--border-radius--small);

  /* box-shadow: var(--shadow-light); */

  @media (--tablet) {
    flex: 0 0 300px;
    float: left;
    width: 300px;
    margin-right: 1.5rem;
  }
}

.c-blogpost__text {
  margin-bottom: 1rem;
}

.c-blogpost__text > h2 {
  font-size: 1.5rem;
}

.c-blogpost__link {
  text-decoration: none;
  transition: color 0.25s;
}

.c-blogpost__link:hover {
  color: var(--color-orange);
}
