.c-footer {
  display: flex;
  grid-column: content / right-gutter;
  grid-row: text;
  flex-direction: column;

  justify-content: space-between;

  padding: 2rem 0;

  @media (--mobile-m) {
    flex-direction: column;
    padding: 3rem 0;
  }

  @media (--tablet) {
    padding: 4rem 0;
  }

  @media (--desktop) {
    flex-direction: row;
  }
}

.c-footer-bg {
  grid-column: 1 / -1;
  grid-row: 1 / footer;

  width: 100%;
  height: 100%;

  background-color: var(--color-sand-light-85);
}

.c-footer-bottom-bg {
  grid-column: 1 / -1;
  grid-row: footer;

  width: 100%;
  height: 100%;

  background-color: var(--color-black);
}

.c-footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
  grid-row: footer;
}
