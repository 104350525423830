.c-gcard {
  position: relative;
  z-index: 1;

  overflow: hidden;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  border-radius: var(--border-radius);

  box-shadow: var(--shadow-normal);
  cursor: pointer;

  transition: transform 0.3s linear, border 0.1s linear;
  will-change: transform;
}

.c-gcard:hover {
  transform: scale(1.01);
}

.c-gcard__group-p-01 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-p-001-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-p-001-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-p-001-1920px.jpg);
  }
}

.c-gcard__group-p-02 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-p-002-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-p-002-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-p-002-1920px.jpg);
  }
}

.c-gcard__group-p-03 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-p-003-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-p-003-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-p-003-1920px.jpg);
  }
}

.c-gcard__group-p-04 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-p-004-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-p-004-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-p-004-1920px.jpg);
  }
}

.c-gcard__group-p-05 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-p-005-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-p-005-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-p-005-1920px.jpg);
  }
}

.c-gcard__group-p-06 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-p-006-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-p-006-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-p-006-1920px.jpg);
  }
}

.c-gcard__group-p-07 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-p-007-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-p-007-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-p-007-1920px.jpg);
  }
}

.c-gcard__group-p-08 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-p-008-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-p-008-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-p-008-1920px.jpg);
  }
}

.c-gcard__group-p-09 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-p-009-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-p-009-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-p-009-1920px.jpg);
  }
}

.c-gcard__group-u-01 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-u-001-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-u-001-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-u-001-1920px.jpg);
  }
}

.c-gcard__group-u-02 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-u-002-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-u-002-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-u-002-1920px.jpg);
  }
}

.c-gcard__group-u-03 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-u-003-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-u-003-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-u-003-1920px.jpg);
  }
}

.c-gcard__group-u-04 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-u-004-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-u-004-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-u-004-1920px.jpg);
  }
}

.c-gcard__group-u-05 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-u-005-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-u-005-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-u-005-1920px.jpg);
  }
}

.c-gcard__group-u-06 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-u-006-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-u-006-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-u-006-1920px.jpg);
  }
}

.c-gcard__group-u-07 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-u-007-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-u-007-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-u-007-1920px.jpg);
  }
}

.c-gcard__group-u-08 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-u-008-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-u-008-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-u-008-1920px.jpg);
  }
}

.c-gcard__group-u-09 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-u-009-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-u-009-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-u-009-1920px.jpg);
  }
}

.c-gcard__group-l-01 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-l-001-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-l-001-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-l-001-1920px.jpg);
  }
}

.c-gcard__group-l-02 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-l-002-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-l-002-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-l-002-1920px.jpg);
  }
}

.c-gcard__group-l-03 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-l-003-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-l-003-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-l-003-1920px.jpg);
  }
}

.c-gcard__group-l-04 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-l-004-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-l-004-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-l-004-1920px.jpg);
  }
}

.c-gcard__group-l-05 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-l-005-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-l-005-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-l-005-1920px.jpg);
  }
}

.c-gcard__group-l-06 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-l-006-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-l-006-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-l-006-1920px.jpg);
  }
}

.c-gcard__group-l-07 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-l-007-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-l-007-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-l-007-1920px.jpg);
  }
}

.c-gcard__group-l-08 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-l-008-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-l-008-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-l-008-1920px.jpg);
  }
}

.c-gcard__group-l-09 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-l-009-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-l-009-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-l-009-1920px.jpg);
  }
}

.c-gcard__group-r-01 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-r-001-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-r-001-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-r-001-1920px.jpg);
  }
}

.c-gcard__group-r-02 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-r-002-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-r-002-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-r-002-1920px.jpg);
  }
}

.c-gcard__group-r-03 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-r-003-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-r-003-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-r-003-1920px.jpg);
  }
}

.c-gcard__group-r-04 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-r-004-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-r-004-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-r-004-1920px.jpg);
  }
}

.c-gcard__group-r-05 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-r-005-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-r-005-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-r-005-1920px.jpg);
  }
}

.c-gcard__group-r-06 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-r-006-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-r-006-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-r-006-1920px.jpg);
  }
}

.c-gcard__group-r-07 {
  grid-column: 1 / -1;
  grid-row: main;

  @media (--laptop) {
    grid-column: main;
    grid-row: 1 / -1;
  }

  background-image: url(../img/gallery-group-r-007-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-r-007-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-r-007-1920px.jpg);
  }
}

.c-gcard__group-r-08 {
  grid-column: 1 / 2;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 1 / 2;
  }

  background-image: url(../img/gallery-group-r-008-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-r-008-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-r-008-1920px.jpg);
  }
}

.c-gcard__group-r-09 {
  grid-column: 2 / -1;
  grid-row: secondary;

  @media (--laptop) {
    grid-column: secondary;
    grid-row: 2 / -1;
  }

  background-image: url(../img/gallery-group-r-009-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-group-r-009-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-group-r-009-1920px.jpg);
  }
}
