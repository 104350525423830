.c-pagination__links {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;

  @media (--mobile-m) {
    padding: 0 1.5rem;
  }

  @media (--tablet) {
    padding: 0;
  }
}
