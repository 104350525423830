.c-bg__h-zimbabwe {
  @mixin bg-hero zimbabwe;
}

.c-bg__h-zimbabwe01 {
  @mixin bg-hero zimbabwe01;
}

.c-bg__h-zimbabwe03 {
  @mixin bg-hero zimbabwe03;
}

.c-bg__s-zimbabwe {
  @mixin bg-section zimbabwe, right;
}

.c-bg__s-zimbabwe-s01 {
  @mixin bg-section zimbabwe-s01, right;
}

.c-bg__s-zimbabwe-s02 {
  @mixin bg-section zimbabwe-s02, left;
}

.c-bg__s-zimbabwe01 {
  @mixin bg-section zimbabwe01, right;
}

.c-bg__s-zimbabwe02 {
  @mixin bg-section zimbabwe02, left;
}

.c-bg__s-zimbabwe03 {
  @mixin bg-section zimbabwe03, right;
}

.c-bg__s-zimbabwe01-s01 {
  @mixin bg-section zimbabwe01-s01, right;
}

.c-bg__s-zimbabwe03-s01 {
  @mixin bg-section zimbabwe03-s01, right;
}
