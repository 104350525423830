.o-hero {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  grid-template-rows: [header] 75px [text] 0.5fr [cards] 1fr [footer] 2rem;
  row-gap: 1rem;

  transition: 1s;

  @media (--tablet) {
    grid-template-rows: [header] 100px [text] 1fr [cards] 1fr [footer] 60px;
  }
}
