.c-tour {
  display: grid;
  width: 100%;

  grid-template-columns: [left-gutter] 1rem [content] 1fr [right-gutter] 1rem;
  grid-template-rows: [tour-price] auto [tour-info] auto;

  row-gap: 3rem;
  margin-bottom: 3rem;

  @media (--mobile-m) {
    grid-template-columns: [left-gutter] 1.5rem [content] 1fr [right-gutter] 1.5rem;
  }

  @media (--tablet) {
    grid-template-columns: [left-gutter] 3rem [content] 1fr [right-gutter] 3rem;
  }

  @media (--laptop) {
    grid-template-columns: [left-gutter] 3rem [tour-price] 4.4fr [tour-info] 5.6fr [right-gutter] 3rem;
    grid-template-rows: auto;

    margin: 4rem 0;
  }

  @media (--desktop-s) {
    grid-template-columns: [left-gutter] 1.5fr [tour-price] 4.4fr [tour-info] 5.6fr [left-gutter] 1.5fr;
    grid-template-rows: auto;
  }

  @media (--desktop) {
    grid-template-columns: [left-gutter] 2fr [tour-price] 4.4fr [tour-info] 5.6fr [left-gutter] 2fr;
    grid-template-rows: auto;
  }

  @media (--widescreen) {
    grid-template-columns: [left-gutter] 3fr [tour-price] 4.4fr [tour-info] 5.6fr [left-gutter] 3fr;
    grid-template-rows: auto;
  }
}

.c-tour__price {
  @media (--laptop) {
    position: sticky;
    top: 150px;
  }

  width: 100%;

  /* @media (--tablet) {
    padding: 2rem;
    background: var(--color-black);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-light);
  } */

  overflow: visible;

  /* padding-bottom: 1.5rem; */

  /* overflow: hidden; */

  color: var(--color-white);

  grid-column: content;
  grid-row: tour-price;

  @media (--laptop) {
    align-self: start;
    grid-column: tour-price;
    grid-row: auto;

    padding-right: 3rem;
  }

  @media (--widescreen) {
    padding-right: 5rem;
  }
}

.c-tour__info {
  width: 100%;

  grid-column: content;
  grid-row: tour-info;

  @media (--laptop) {
    grid-column: tour-info;
    grid-row: auto;
  }
}

.c-tour__map {
  width: 100%;

  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
}

.c-tour__wrap {
  display: inline-block;
}

.c-tour--old {
  display: grid;
  width: 100%;

  grid-template-columns: [content] 1fr;
  grid-template-rows: [tour-price] auto [tour-info] auto;

  row-gap: 2rem;
  column-gap: 2rem;

  grid-column: content;

  @media (--laptop) {
    grid-template-columns: [tour-price] 1fr [tour-info] 1.5fr;
    grid-template-rows: auto;
  }

  @media (--desktop) {
    grid-template-columns: [tour-price] 1fr [tour-info] 1.5fr;
    grid-template-rows: auto;
  }
}

.c-tour__price--old {
  width: 100%;

  padding-bottom: 1.5rem;

  overflow: hidden;

  color: var(--color-white);

  grid-column: content;
  grid-row: tour-price;

  /* @media (--tablet) {
    padding: 2rem;
    background: var(--color-black);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-light);
  } */

  @media (--laptop) {
    grid-column: tour-price;
    grid-row: auto;
    align-self: start;
  }
}
