.c-address {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  font-style: normal;

  @media (--desktop) {
    max-width: 390px;
    padding-top: 0.3rem;
  }
}

/* .c-address > * + * {
  margin-top: 2rem;

  @media (--mobile-m) {
    margin-top: 3rem;
  }
} */

.c-address__wrap {
  flex: 1 1 390px;

  @media (--tablet) {
    flex: 0 0 390px;
  }
}

.c-address__wrap:first-child {
  margin-bottom: 2rem;

  @media (--mobile-m) {
    margin-bottom: 3rem;
  }

  @media (--tablet) {
    margin-right: 2rem;
  }
}

.c-address__title {
  margin-top: 0;
  font-size: 1.5rem;
  text-shadow: var(--shadow-text);
}

.c-address__list {
  /* margin: 0 0 3rem; */
  padding: 0;
  list-style-type: none;
}

.c-address__list-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
}

.c-address__list-item:last-child {
  margin-bottom: 0;
}

.c-address__icon {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-right: 1rem;
}

.c-address__text {
  margin: 0;
}

.c-address__mail {
  direction: rtl;
  unicode-bidi: bidi-override;
}

.c-address__link {
  transition: color 0.25s;
}

.c-address__link:hover {
  color: var(--color-orange);
}
