.c-car-spec {
  margin-bottom: 3rem;

  padding-left: 1.75rem;
  list-style-type: disc;
}

.c-car-spec > li {
  padding-bottom: 0.5rem;
}
