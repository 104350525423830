@define-mixin bg-img $name {
  background-image: url('../img/$(name)-1024px.jpg');

  @media (--laptop) {
    background-image: url('../img/$(name)-1440px.jpg');
  }

  @media (--desktop) {
    background-image: url('../img/$(name)-1920px.jpg');
  }
}

@define-mixin bg-section $name, $align {
  background-image: url('../img/s-$(name)-768.jpg');
  background-position: $align;

  @media (--tablet) {
    background-image: url('../img/s-$(name)-1024.jpg');
  }

  @media (--laptop) {
    background-image: url('../img/s-$(name)-1440.jpg');
  }

  @media (--desktop-s) {
    background-image: url('../img/s-$(name)-1920.jpg');
  }

  @media (--widescreen) {
    background-image: url('../img/s-$(name)-2560.jpg');
  }
}

@define-mixin bg-day $name {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background-image: url('../img/$(name).jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  transition: transform 0.25s ease-in-out;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.4) 100%); */
    background: rgba(0, 0, 0, 0.2);
    content: '';
  }
}

@define-mixin bg-hero $name, $align: center {
  background-image: url('../img/h-mobile-$(name).jpg');
  background-position: $align;

  @media (--mobile-m-x2) {
    background-image: url('../img/h-mobile-$(name)@2.jpg');
  }

  @media (--tablet) {
    background-image: url('../img/h-tablet-$(name).jpg');
  }

  @media (--tablet-x2) {
    background-image: url('../img/h-tablet-$(name)@2.jpg');
  }

  @media (--laptop) {
    @media (min-aspect-ratio: 3/4) {
      background-image: url('../img/h-tablet-$(name)@2.jpg');
    }

    @media (min-aspect-ratio: 16/9) {
      background-image: url('../img/h-desktop-$(name)-1440px.jpg');
    }
  }

  @media (--desktop) {
    background-image: url('../img/h-desktop-$(name)-1920px.jpg');
  }

  @media (--widescreen) {
    background-image: url('../img/h-desktop-$(name)-2560px.jpg');
  }
}
