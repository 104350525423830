.c-map-section {
  position: relative;
  display: grid;

  width: 100%;

  overflow: hidden;

  grid-template-columns: [left-gutter] 1rem [content] minmax(0, 1fr) [right-gutter] 1rem;
  grid-template-rows: [header] 2rem [title] max-content [map] min-content [text] min-content [footer] 2rem;

  @media (--mobile-m) {
    grid-template-columns: [left-gutter] 1.5rem [content] minmax(0, 1fr) [right-gutter] 1.5rem;
    grid-template-rows: [header] 3rem [title] max-content [map] min-content [text] min-content [footer] 3rem;
  }

  @media (--tablet) {
    grid-template-columns: [left-gutter] 3rem [content] 10fr [right-gutter] 3rem;
    grid-template-rows: [header] 5rem [title] max-content [map] min-content [text] min-content [footer] 5rem;
  }

  @media (--laptop) {
    grid-template-columns: [left-gutter] 3rem [content] 4.85fr [empty] 5.15fr [right-gutter] 3rem;
    grid-template-rows: [header] 7rem [title] max-content [content] min-content [footer] 7rem;
  }

  @media (--desktop-s) {
    grid-template-columns: [left-gutter] 1.5fr [content] 4.8fr [empty] 5.2fr [right-gutter] 1.5fr;
  }

  @media (--desktop) {
    grid-template-columns: [left-gutter] 2fr [content] 4.44fr [empty] 5.56fr [right-gutter] 2fr;
  }

  @media (--widescreen) {
    grid-template-columns: [left-gutter] 3fr [content] 4.35fr [empty] 5.65fr [right-gutter] 3fr;
  }
}

.c-map-section__title {
  grid-column: content;
  grid-row: title;

  @media (--laptop) {
    padding-right: 3rem;
  }
}

.c-map-section__text {
  grid-column: content;
  grid-row: text;
  margin-top: 1rem;

  @media (--mobile-m) {
    margin-top: 2rem;
  }

  @media (--laptop) {
    margin-top: 0;
    padding-right: 3rem;
    grid-row: content;
  }
}

.c-map-section__map {
  grid-column: left-gutter / -1;
  grid-row: map;
  min-height: 50vh;

  @media (--laptop) {
    grid-column: empty / -1;
    grid-row: header / -1;
    box-shadow: var(--shadow-light);
  }
}
