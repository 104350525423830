.c-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  align-items: center;
  align-self: auto;
  grid-row: header;
  grid-column: content / right-gutter;
}

.c-header-bg {
  background: var(--color-sand-light-85);
}

.c-header__logo {
  display: flex;
  align-items: center;
}

.c-header__phone {
  margin-left: 0.6rem;
  font-family: 'Oswald', sans-serif;

  @media (--tablet) {
    font-size: 1.125rem;
    margin-left: 1rem;
  }
}