.c-cards {
  display: grid;

  margin-bottom: calc(-0.5 * 16px);
  padding-top: 30px;
  padding-bottom: 46px;

  overflow-x: scroll;
  overflow-y: hidden;

  grid-column-gap: 8px;
  grid-template-rows: 235px;
  grid-template-columns: 8px;
  grid-auto-flow: column;
  grid-auto-columns: minmax(224px, calc(50% - 24px));
  grid-row: cards;

  scroll-snap-type: x proximity;

  @media (--mobile-m) {
    grid-template-columns: 16px;
  }

  @media (--tablet) {
    margin: 0;
    padding: 0 0 46px;

    overflow: visible;

    grid-template-columns: repeat(3, 200px);
    grid-template-rows: 235px;
    grid-auto-rows: 235px;
    grid-auto-flow: row;
    grid-gap: 32px;
  }

  @media (--laptop) {
    grid-template-columns: repeat(3, 200px);
    grid-template-rows: 235px;
  }
}

.c-cards--four {
  /* @media (--tablet) {
    margin: 0;
    padding: 0;

    overflow: visible;

    grid-template-columns: repeat(2, 200px);
    grid-template-rows: 235px;
    grid-auto-rows: 235px;
    grid-auto-flow: row;
    grid-gap: 32px;
  } */

  @media (--laptop) {
    grid-template-columns: repeat(4, 200px);
    grid-template-rows: 235px;
  }
}

.c-cards::before,
.c-cards::after {
  content: '';
  width: 0;

  @media (--tablet) {
    display: none;
  }
}

.c-cards--left {
  @media (--laptop) {
    justify-content: start;
  }
}

.c-cards--right {
  @media (--laptop) {
    justify-content: end;
  }
}
