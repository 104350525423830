.c-icon-down {
  width: 28px;

  @media (--tablet) {
    width: 32px;
  }

  transition: transform 0.3s ease-in-out;
}

.c-icon-right {
  width: 28px;

  transform: rotate(-90deg);

  @media (--tablet) {
    width: 32px;
  }

  transition: transform 0.3s ease-in-out;
}
