.c-scroll-up {
  position: fixed;
  right: 16px;
  bottom: 60px;
  z-index: 100;

  display: block;
  padding: 0.5rem;
  background: var(--color-sand-light-85);
  border-radius: 5px;

  cursor: pointer;

  opacity: 0;

  transition: opacity 0.25s ease-in-out;

  @media (--tablet) {
    right: 32px;
    bottom: 60px;
  }
}

.c-scroll-up__icon {
  width: 32px;
  height: 32px;
}
