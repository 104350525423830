.c-bg__h-group-p {
  @mixin bg-hero group-p;
}

/* .c-bg__hero-group-p {
  background-image: url(../img/hero-group-p-1024px.jpg);
  background-position: center center;

  @media (--tablet) {
    background-position: center center;
  }

  @media (--laptop) {
    background-image: url(../img/hero-group-p-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-group-p-1920px.jpg);
  }
} */

.c-bg__group-p-desc {
  background-image: url(../img/desc-group-p-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/desc-group-p-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/desc-group-p-1920px.jpg);
  }
}

.c-bg__group-p-spec {
  background-image: url(../img/spec-group-p-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/spec-group-p-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/spec-group-p-1920px.jpg);
  }
}

.c-bg__footer-group-p {
  background-image: url(../img/section-footer-group-p-1024px.jpg);
  background-position: center top;

  @media (--laptop) {
    background-image: url(../img/section-footer-group-p-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-footer-group-p-1920px.jpg);
  }
}

.c-bg__hero-group-p-equipment {
  background-image: url(../img/hero-group-p-equipment-1024px.jpg);
  background-position: center bottom;

  @media (--laptop) {
    background-image: url(../img/hero-group-p-equipment-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-group-p-equipment-1920px.jpg);
  }
}

.c-bg__hero-group-p-equipment::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  content: '';

  @media (--tablet) {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.c-bg__gallery-group-p-01 {
  background-image: url(../img/gallery-bg-group-p-001-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-p-001-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-p-001-1920px.jpg);
  }
}

.c-bg__gallery-group-p-01::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}

.c-bg__gallery-group-p-02 {
  background-image: url(../img/gallery-bg-group-p-002-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-p-002-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-p-002-1920px.jpg);
  }
}

.c-bg__gallery-group-p-02::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}

.c-bg__gallery-group-p-03 {
  background-image: url(../img/gallery-bg-group-p-003-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-p-003-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-p-003-1920px.jpg);
  }
}

.c-bg__gallery-group-p-03::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}
