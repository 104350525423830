.o-header {
  position: fixed;
  z-index: 10;
  width: 100%;

  overflow: visible;

  grid-template-rows: [header] 75px;

  @media (--tablet) {
    grid-template-rows: [header] 100px;
  }
}

.o-header--fixed {
  position: fixed;
}
