.c-posts {
  grid-column: content / right-gutter;

  margin-top: 1rem;
  margin-bottom: 3rem;

  @media (--desktop) {
    grid-column: content / recent;
    padding-right: 6rem;
  }
}

.c-posts__post {
  position: relative;
  width: 100%;
  margin-bottom: 4rem;
  overflow: hidden;

  background: var(--color-sand-light-dark);

  border-radius: var(--border-radius--small);
  box-shadow: var(--shadow-light);

  @media (--laptop) {
    margin-bottom: 6rem;
  }
}

.c-posts__post:last-child {
  margin-bottom: 3rem;
}

.c-posts__head {
  position: relative;
}

.c-posts__title-image {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 230px;

  padding: 2rem 1rem 1.5rem;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media (--tablet) {
    height: 400px;
    padding: 2rem 2rem 1.5rem;
  }
}

.c-posts__title-image::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.4);
  content: '';
}

.c-posts__title {
  color: var(--color-white);

  font-size: 1.5rem;
  text-shadow: var(--shadow-text);

  @media (--tablet) {
    font-size: 3rem;
  }
}

.c-posts__author {
  width: 100%;

  margin: 0 auto 0.5rem;

  color: var(--color-orange);
  font-weight: 500;
  font-size: 1rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.1;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--desktop) {
    font-size: 1.5rem;
  }
}

.c-posts__date {
  width: 100%;
  margin: 0 auto 0.5rem;

  color: var(--color-orange);
  font-weight: 500;
  font-size: 1rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.1;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--desktop) {
    font-size: 1.5rem;
  }
}

/* .c-posts__date {
  color: var(--color-white);
} */

.c-posts__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1.5rem 1rem 2rem;

  @media (--tablet) {
    padding: 1.5rem 2rem 2rem;
  }
}

.c-posts__content > .c-btn {
  align-self: center;
  margin-top: 1.5rem;

  @media (--tablet) {
    align-self: flex-end;
  }
}
