.c-sidepanel {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;

  /* display: flex; */
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  overflow: auto;

  color: var(--color-black);
  background-color: var(--color-sand);
  transform: translateX(100vw);
  transition: transform 0.5s var(--ease-in-out-circ);

  @media (--tablet) {
    width: 400px;
  }
}

.c-sidepanel--open {
  display: flex;
  transform: translateX(0);
}

.c-clsbtn {
  position: absolute;
  top: 24px;
  right: 16px;

  color: var(--color-black);
  cursor: pointer;

  @media (--mobile-m) {
    right: 28px;
  }

  @media (--laptop) {
    right: 48px;
    top: 38px;
  }

  svg #close {
    transition: all 0.25s ease-in-out;
    fill: (--color-black);
  }

  svg:hover #close {
    fill: var(--color-white);
  }
}
