.c-slider {
  position: relative;
  width: 100%;

  height: 100%;

  /* @media (--mobile-m) {
    height: 150px;
  }

  @media (--mobile-l) {
    height: 140px;
  }

  @media (--laptop) {
    height: 140px;
  }

  @media (--desktop) {
    height: 130px;
  } */
}

.c-slide {
  position: absolute;
  width: 100%;
  height: 100%;

  transition: opacity 0.5s ease-in;
}

.c-slide--main {
  padding-top: 40px;

  @media (--mobile-m) {
    padding-top: 80px;
  }

  @media (--laptop) {
    padding-top: 180px;
  }
}

.c-slide:not(.c-slide--main) {
  margin: auto;
}

.c-slide + .c-slide {
  display: none;
  opacity: 0;
}

.c-slide__title {
  margin: 0 auto 1rem;
  font-weight: 300;

  font-size: 1.7rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.2;
  letter-spacing: 4px;
  text-align: center;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--tablet) {
    font-size: 3rem;
  }

  @media (--laptop) {
    font-size: 3rem;
  }
}

.c-slide__title:not(.c-slide__main-title) {
  align-self: center;
  padding-bottom: 54px;
}

.c-slide__main-title {
  font-weight: 400;
  font-size: 2rem;

  @media (--tablet) {
    font-size: 3.5rem;
  }

  @media (--desktop) {
    font-size: 4.5rem;
  }
}

.c-slide-bg {
  position: absolute;

  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  transition: opacity 0.5s ease-in;
}

.c-slide-bg::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.1);
  content: '';
}

.c-slide-bg__01 {
  @mixin bg-hero slide01;
}

.c-slide-bg__02 {
  @mixin bg-hero slide02;
}

.c-slide-bg__03 {
  @mixin bg-hero slide03;
}

.c-slide-bg__04 {
  @mixin bg-hero slide04;
}

.c-slide-bg__05 {
  @mixin bg-hero slide05;
}

.c-slide-bg__06 {
  @mixin bg-hero slide06;
}

.c-slide-bg__07 {
  @mixin bg-hero slide07;
}

.c-slide-bg__08 {
  @mixin bg-hero slide08;
}

.c-slide-bg__09 {
  @mixin bg-hero slide09;
}

.c-slide-bg__10 {
  @mixin bg-hero slide10;
}

.c-slide-bg__11 {
  @mixin bg-hero slide11;
}

.c-slide-bg__12 {
  @mixin bg-hero slide12;
}

.c-slide-bg__13 {
  @mixin bg-hero slide13;
}

/* .c-slide-bg__01 {
  background-image: url(../img/hero-slide-bg-01-1024px.jpg);
  background-position: center right -245px;

  @media (--tablet) {
    background-position: center center;
  }

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-01-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-01-1920px.jpg);
  }
}

.c-slide-bg__02 {
  background-image: url(../img/hero-slide-bg-02-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-02-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-02-1920px.jpg);
  }
}

.c-slide-bg__03 {
  background-image: url(../img/hero-slide-bg-03-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-03-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-03-1920px.jpg);
  }
}

.c-slide-bg__04 {
  background-image: url(../img/hero-slide-bg-04-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-04-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-04-1920px.jpg);
  }
}

.c-slide-bg__05 {
  background-image: url(../img/hero-slide-bg-05-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-05-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-05-1920px.jpg);
  }
}

.c-slide-bg__06 {
  background-image: url(../img/hero-slide-bg-06-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-06-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-06-1920px.jpg);
  }
}

.c-slide-bg__07 {
  background-image: url(../img/hero-slide-bg-07-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-07-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-07-1920px.jpg);
  }
}

.c-slide-bg__08 {
  background-image: url(../img/hero-slide-bg-08-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-08-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-08-1920px.jpg);
  }
}

.c-slide-bg__09 {
  background-image: url(../img/hero-slide-bg-09-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-09-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-09-1920px.jpg);
  }
}

.c-slide-bg__10 {
  background-image: url(../img/hero-slide-bg-10-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-10-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-10-1920px.jpg);
  }
}

.c-slide-bg__11 {
  background-image: url(../img/hero-slide-bg-11-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-11-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-11-1920px.jpg);
  }
}

.c-slide-bg__12 {
  background-image: url(../img/hero-slide-bg-12-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-12-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-12-1920px.jpg);
  }
}

.c-slide-bg__13 {
  background-image: url(../img/hero-slide-bg-13-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/hero-slide-bg-13-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-slide-bg-13-1920px.jpg);
  }
} */
