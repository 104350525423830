.c-car-card {
  position: relative;
  top: -268px;

  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 105%;
  height: 105%;

  padding: 1.5rem 1rem;

  transition: transform 0.15s var(--ease-out-cubic);
}

.c-car-card__group-p {
  background-image: url(../img/card-group-p.jpg);
}

.c-car-card__group-u {
  background-image: url(../img/card-group-u.jpg);
}

.c-car-card__group-l {
  background-image: url(../img/card-group-l.jpg);
}

.c-car-card__group-r {
  background-image: url(../img/card-group-r.jpg);
}

.c-car-card::after {
  position: absolute;
  top: -5px;
  left: 0;

  z-index: -1;

  width: 100%;
  height: 105%;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.25%, rgba(2, 2, 2, 0.6) 100%);

  content: '';
}

.c-car-card__title {
  margin: 0 0 0.5rem;

  color: var(--color-orange);
  font-weight: 500;
  font-size: 36px;
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  line-height: 1.2;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);
}

.c-car-card__description {
  color: var(--color-white);
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
}
