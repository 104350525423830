.c-card {
  width: 200px;
  height: 235px;
  overflow: hidden;

  /* border: 1px solid var(--color-sand); */
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-normal);

  transform: translateZ(0);
  transform-style: preserve-3d;

  backface-visibility: hidden;

  transition: transform 0.3s linear, border 0.1s linear;
  will-change: transform;
}

.c-card__content {
  transform: translateZ(0);
  transform-style: preserve-3d;
}

.c-card:hover {
  /* border: 1px solid var(--color-sand-light); */
}

.c-card__link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  transform: translateZ(11px);
}

.c-card__bg {
  position: relative;
  top: -20px;
  left: -20px;

  width: 115%;
  height: 115%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  transition: all 0.3s linear;
}

.c-card-wrapper {
  /* transform: scale(0); */

  /* scroll-snap-align: center; */
}
