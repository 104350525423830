.c-bg__h-botswana {
  @mixin bg-hero botswana, top;
}

.c-bg__s-botswana {
  @mixin bg-section botswana, left;
}

.c-bg__s-botswana-s01 {
  @mixin bg-section botswana-s01, right;
}

.c-bg__s-botswana-s02 {
  @mixin bg-section botswana-s02, left;
}

.c-bg__s-botswana01 {
  @mixin bg-section botswana01, right;
}

.c-bg__s-botswana02 {
  @mixin bg-section botswana02, left;
}

.c-bg__s-botswana03 {
  @mixin bg-section botswana03, right;
}

.c-bg__botswana-01 {
  background-image: url(../img/section-botswana-01-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/section-botswana-01-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-botswana-01-1920px.jpg);
  }
}

.c-bg__botswana-02 {
  background-image: url(../img/section-botswana-02-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/section-botswana-02-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-botswana-02-1920px.jpg);
  }
}

.c-bg__botswana-classic {
  background-image: url(../img/section-botswana-classic-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/section-botswana-classic-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-botswana-classic-1920px.jpg);
  }
}

.c-bg__botswana-twist {
  background-image: url(../img/section-botswana-twist-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/section-botswana-twist-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-botswana-twist-1920px.jpg);
  }
}

.c-bg__botswana-luxury {
  background-image: url(../img/section-botswana-luxury-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/section-botswana-luxury-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-botswana-luxury-1920px.jpg);
  }
}
