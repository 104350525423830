.c-bg__gtour-02-day-01 {
  background-image: url(../img/group-tour-02-day-01.jpg);
}

.c-bg__gtour-02-day-02 {
  background-image: url(../img/group-tour-02-day-02.jpg);
}

.c-bg__gtour-02-day-03 {
  background-image: url(../img/group-tour-02-day-03.jpg);
}

.c-bg__gtour-02-day-04 {
  background-image: url(../img/group-tour-02-day-04.jpg);
}

.c-bg__gtour-02-day-05 {
  background-image: url(../img/group-tour-02-day-05.jpg);
}

.c-bg__gtour-02-day-06 {
  background-image: url(../img/group-tour-02-day-06.jpg);
}

.c-bg__gtour-02-day-07 {
  background-image: url(../img/group-tour-02-day-07.jpg);
}

.c-bg__gtour-02-day-08 {
  background-image: url(../img/group-tour-02-day-08.jpg);
}

.c-bg__gtour-02-day-09 {
  background-image: url(../img/group-tour-02-day-09.jpg);
}

.c-bg__gtour-02-day-10 {
  background-image: url(../img/group-tour-02-day-10.jpg);
}

.c-bg__gtour-02-day-11 {
  background-image: url(../img/group-tour-02-day-11.jpg);
}

.c-bg__gtour-02-day-12 {
  background-image: url(../img/group-tour-02-day-12.jpg);
}

.c-bg__gtour-02-day-13 {
  background-image: url(../img/group-tour-02-day-13.jpg);
}

.c-bg__gtour-02-day-14 {
  background-image: url(../img/group-tour-02-day-14.jpg);
}
