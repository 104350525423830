.c-day {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;

  /* margin-bottom: 1.5rem;

  @media (--tablet) {
    margin-bottom: 2rem;
  } */
}

.c-day__title {
  display: inline-flex;
  flex: 2 1 250px;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin: 2rem;
  padding: 0 1rem;

  color: var(--color-black);
  font-style: normal;
  line-height: 1.2;
  text-align: center;
  text-shadow: var(--shadow-text);

  @media (--tablet) {
    align-self: center;

    margin: 2rem 0;
    padding: 0 2rem;
  }
}

.c-day__title > span:first-child {
  margin-bottom: 0.5rem;

  font-weight: 500;
  font-size: 1.7rem;
  font-family: Oswald, sans-serif;
  text-transform: uppercase;

  @media (--tablet) {
    font-size: 2rem;
  }
}

.c-day__title > span:last-child {
  color: var(--color-green);
  font-weight: 600;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

.c-day__img {
  flex: 1 1 380px;

  width: 100%;
  height: 200px;
  margin-bottom: 1rem;

  overflow: hidden;

  border: 1px solid var(--color-sand);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-normal);

  @media (--tablet) {
    height: 260px;
  }

  @media (--laptop) {
    margin-bottom: 1.5rem;
  }
}

.c-day__info {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.c-day__info-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.c-day__info-list > li {
  /* line-height: 1.5rem; */
}

.c-day__accommodation {
  display: flex;
  align-items: center;
}

.c-day__accommodation-icon {
  display: inline-block;
  width: 74px;

  cursor: pointer;
}

.c-day__image {
  position: relative;
  z-index: 1;

  width: 100%;

  padding: 1rem;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media (--mobile-m) {
    padding: 1rem 1.5rem;
  }

  /* @media (--tablet) {
    padding: 1.5rem;
  } */

  @media (--laptop) {
    padding: 1.5rem 2rem;
  }
}

.c-day__image::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.4);
  content: '';
}
