.c-info {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.c-info > li {
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
  font-size: 0.875rem;

  @media (--mobile-m) {
    font-size: 1rem;
  }
}

.c-info > li:last-child {
  margin-bottom: 0;
}

.c-info__icon {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-right: 1rem;
  color: var(--color-yellow);
}

.c-info__link {
  color: var(--color-white);
  transition: color 0.25s;
}

.c-info__link:hover {
  color: var(--color-orange);
}
