.c-icon-btn {
  padding: 14px;
  font-weight: 400;
  font-size: 0.875rem;
  font-family: Oswald, sans-serif;
  line-height: 1;

  cursor: pointer;

  transition: all 0.25s ease-in;
}

.c-icon-btn:hover {
  color: var(--color-white);
}

.c-icon-btn--collapse {
  padding-left: 30px;
}

.c-icon-btn--collapse::before {
  position: absolute;
  width: 14px;
  height: 14px;

  margin-top: 1px;
  margin-left: -18px;

  background-color: var(--color-black);

  transition: all 0.25s ease-in;
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 37 37' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' %3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  content: '';
}

.c-icon-btn--collapse:hover::before {
  background-color: var(--color-white);
}

.c-icon-btn--expand::before {
  position: absolute;
  width: 14px;
  height: 14px;

  margin-top: 1px;
  margin-left: -18px;

  background-color: var(--color-black);

  transition: all 0.25s ease-in;
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 37 37' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='16'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  content: '';
}

.c-icon-btn--expand:hover::before {
  background-color: var(--color-white);
}
