.c-hmenu {
  display: flex;
  align-items: center;
  margin: 0;
}

.c-hmenu > * + * {
  margin-left: 2rem;
}

.c-hmenu-item {
  cursor: pointer;
}

.c-hmenu__item-link {
  position: relative;
  z-index: 1;

  margin-top: 0;
  margin-bottom: 0;

  color: var(--color-black);
  font-size: 1.125rem;

  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
}

.c-hmenu__item-link,
.c-hmenu__item-link::before,
.c-hmenu__item-link::after,
.c-hmenu__dropmenu-icon,
svg #hamburger {
  transition: color 0.25s, fill 0.25s;
}

.c-hmenu__item-link--light {
  color: var(--color-sand);
}

.c-hmenu__item-link--light:hover {
  color: var(--color-sand-light);
}

.c-hmenu__item-link--disabled {
  position: relative;
  z-index: 1;

  margin-top: 0;
  margin-bottom: 0;

  color: var(--color-black);
  font-size: 1.125rem;

  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}

.c-hmenu__dropmenu {
  position: relative;
  display: flex;
  align-items: center;
}

.c-hmenu__dropmenu-icon {
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  color: var(--color-black);
}

.c-hmenu__dropmenu-icon--light {
  color: var(--color-sand);
}

.c-hmenu__item-hamburger a {
  display: block;
  height: 2rem;
  margin-left: 2rem;
  cursor: pointer;
}

.c-hamburger #hamburger {
  fill: var(--color-black);
}

.c-hamburger--light #hamburger {
  fill: var(--color-sand);
}

.c-hmenu__item:hover .c-hmenu__item-link {
  color: var(--color-orange);

  svg #hamburger {
    fill: var(--color-orange);
  }
}

.c-hmenu__item:hover .c-hmenu__item-link--disabled {
  color: var(--color-black);
}


.c-hmenu__item:hover .c-hmenu__item-link--light {
  color: var(--color-sand-light);

  .c-hamburger--light #hamburger {
    fill: var(--color-sand-light);
  }
}

.c-hmenu__item:hover .c-hmenu__dropmenu-icon {
  fill: var(--color-orange);
}

.c-hmenu__item:hover .c-hmenu__dropmenu-icon--light {
  fill: var(--color-sand-light);
}

.c-hmenu__item--mobile {
  @media (--desktop-s) {
    display: none;
  }
}

.c-hmenu__item--desktop {
  display: none;

  @media (--laptop) {
    display: block;
  }
}

.c-hmenu__item--tablet {
  display: none;

  @media (--desktop-s) {
    display: block;
  }
}

.c-hamburger {
  height: 2rem;
}

.c-dropdown-menu {
  position: absolute;
  z-index: 1;
  display: block;
  min-width: 230px;
  overflow: hidden;
  text-align: left;
  background-color: var(--color-sand-light);

  border-radius: 8px;
  box-shadow: var(--shadow-normal);
  visibility: hidden;
  opacity: 0;

  transition: opacity 0.5s ease-in-out;
}

.c-dropdown-menu__item-link {
  position: relative;
  z-index: 1;

  display: block;
  padding: 1rem 1.5rem;

  color: var(--color-black);
  font-size: 1.125rem;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.c-dropdown-menu:hover,
.c-hmenu__item:hover > .c-dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
}

.c-dropdown-menu__item-link:hover {
  color: var(--color-orange);
  background: var(--color-black);
}
