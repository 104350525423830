.c-bg__h-group-l {
  @mixin bg-hero group-l;
}

/* .c-bg__hero-group-l {
  background-image: url(../img/hero-group-l-1024px.jpg);
  background-position: center top;

  @media (--laptop) {
    background-image: url(../img/hero-group-l-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-group-l-1920px.jpg);
  }
} */

.c-bg__group-l-desc {
  background-image: url(../img/desc-group-l-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/desc-group-l-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/desc-group-l-1920px.jpg);
  }
}

.c-bg__group-l-spec {
  background-image: url(../img/spec-group-l-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/spec-group-l-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/spec-group-l-1920px.jpg);
  }
}

.c-bg__footer-group-l {
  background-image: url(../img/section-footer-group-l-1024px.jpg);
  background-position: center top;

  @media (--laptop) {
    background-image: url(../img/section-footer-group-l-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-footer-group-l-1920px.jpg);
  }
}

.c-bg__hero-group-l-equipment {
  background-image: url(../img/hero-group-l-equipment-1024px.jpg);
  background-position: center bottom;

  @media (--laptop) {
    background-image: url(../img/hero-group-l-equipment-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-group-l-equipment-1920px.jpg);
  }
}

.c-bg__hero-group-l-equipment::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
  content: '';

  @media (--tablet) {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.c-bg__gallery-group-l-01 {
  background-image: url(../img/gallery-bg-group-l-001-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-l-001-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-l-001-1920px.jpg);
  }
}

.c-bg__gallery-group-l-01::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}

.c-bg__gallery-group-l-02 {
  background-image: url(../img/gallery-bg-group-l-002-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-l-002-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-l-002-1920px.jpg);
  }
}

.c-bg__gallery-group-l-02::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}

.c-bg__gallery-group-l-03 {
  background-image: url(../img/gallery-bg-group-l-003-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/gallery-bg-group-l-003-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/gallery-bg-group-l-003-1920px.jpg);
  }
}

.c-bg__gallery-group-l-03::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: var(--color-sand-85);
  content: '';
}
