.c-tour-hero {
  @media (--laptop) {
    grid-row: text / footer;
  }
}

/* .c-tour-hero .c-hero__subtitle {
  margin: 0 auto 2rem;

  color: var(--color-white);
  text-align: center;
  text-transform: none;

  @media (--hero) {
    margin: 0 0 2rem;
    font-size: 2.25rem;
    text-align: left;
  }
} */
