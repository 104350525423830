.c-tour-card {
  position: relative;

  top: -268px;

  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 235px;

  padding: 1.5rem 1rem;

  transition: transform 0.15s var(--ease-out-cubic);
}

.c-tour-card::after {
  position: absolute;
  top: -5px;
  left: 0;

  z-index: -1;

  width: 100%;
  height: 105%;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 53.13%, rgba(0, 0, 0, 0.3) 100%);

  border-radius: var(--border-radius--small);
  content: '';
}

.c-tour-card__title-text {
  margin: 0 0 4px;
  color: var(--color-white);
  font-weight: 400;
  font-size: 24px;
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  line-height: 1;
  text-shadow: var(--shadow-text);
}

.c-tour-card__description {
  color: var(--color-white);
  font-weight: 400;
  font-size: 12px;
  font-family: 'Oswald', sans-serif;
  font-style: normal;
  line-height: 1;
  text-shadow: var(--shadow-text);
}

.c-tour-card__date {
  margin-top: 12px;
  color: var(--color-white);
  font-weight: 400;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  line-height: 1;
  text-shadow: var(--shadow-text);
}

.c-tour-card__info {
  display: flex;
  color: var(--color-white);
  font-weight: 400;
  font-family: 'Roboto Condensed', sans-serif;

  /* font-family: 'Open Sans', sans-serif; */
  font-style: normal;
  text-shadow: var(--shadow-text);

  & > p {
    font-size: 1.2rem;
  }
}

.c-tour-card__info > * + * {
  margin-left: 0.5rem;
}

.c-tour-card__group-tour-01 {
  background-image: url(../img/card-group-tour-01.jpg);
}

.c-tour-card__group-tour-02 {
  background-image: url(../img/card-group-tour-02.jpg);
}

.c-tour-card__group-tour-03 {
  background-image: url(../img/card-group-tour-03.jpg);
}

.c-tour-card__namibia-tour01 {
  background-image: url(../img/card-namibia-tour-01.jpg);
}

.c-tour-card__namibia-tour02 {
  background-image: url(../img/card-namibia-tour-02.jpg);
}

.c-tour-card__namibia-tour03 {
  background-image: url(../img/card-namibia-tour-03.jpg);
}

.c-tour-card__namibia-tour04 {
  background-image: url(../img/card-namibia-tour-04.jpg);
}

.c-tour-card__zambia-tour01 {
  background-image: url(../img/card-zambia-tour-01.jpg);
}

.c-tour-card__zambia-tour02 {
  background-image: url(../img/card-zambia-tour-02.jpg);
}

.c-tour-card__zambia-tour03 {
  background-image: url(../img/card-zambia-tour-03.jpg);
}

.c-tour-card__botswana-tour01 {
  background-image: url(../img/card-botswana-tour-01.jpg);
}

.c-tour-card__botswana-tour02 {
  background-image: url(../img/card-botswana-tour-02.jpg);
}

.c-tour-card__botswana-tour03 {
  background-image: url(../img/card-botswana-tour-03.jpg);
}

.c-tour-card__mozambique-tour01 {
  background-image: url(../img/card-mozambique-tour-01.jpg);
}

.c-tour-card__mozambique-tour02 {
  background-image: url(../img/card-mozambique-tour-02.jpg);
}

.c-tour-card__mozambique-tour03 {
  background-image: url(../img/card-mozambique-tour-03.jpg);
}

.c-tour-card__southafrica-tour01 {
  background-image: url(../img/card-southafrica-tour-01.jpg);
}

.c-tour-card__southafrica-tour02 {
  background-image: url(../img/card-southafrica-tour-02.jpg);
}

.c-tour-card__southafrica-tour03 {
  background-image: url(../img/card-southafrica-tour-03.jpg);
}

.c-tour-card__zimbabwe-tour01 {
  background-image: url(../img/card-zimbabwe-tour-01.jpg);
}

.c-tour-card__zimbabwe-tour02 {
  background-image: url(../img/card-zimbabwe-tour-02.jpg);
}

.c-tour-card__zimbabwe-tour03 {
  background-image: url(../img/card-zimbabwe-tour-03.jpg);
}
