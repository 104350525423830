/*
  Namibia heros
*/
.c-bg__h-namibia {
  @mixin bg-hero namibia;
}

.c-bg__h-namibia01 {
  @mixin bg-hero namibia01;
}

.c-bg__h-namibia02 {
  @mixin bg-hero namibia02;
}

.c-bg__h-namibia03 {
  @mixin bg-hero namibia03;
}

.c-bg__h-namibia04 {
  @mixin bg-hero namibia04;
}

.c-bg__s-namibia {
  @mixin bg-section namibia, right;
}

.c-bg__s-namibia-s01 {
  @mixin bg-section namibia-s01, right;
}

.c-bg__s-namibia-s02 {
  @mixin bg-section namibia-s02, left;
}

/*
  Namibia tours info blocks
*/
.c-bg__s-namibia01 {
  @mixin bg-section namibia01, right;
}

.c-bg__s-namibia02 {
  @mixin bg-section namibia02, right;
}

.c-bg__s-namibia03 {
  @mixin bg-section namibia03, right;
}

.c-bg__s-namibia04 {
  @mixin bg-section namibia04, right;
}

/*
  Namibia tour 01
*/
.c-bg__namibia01-day01 {
  @mixin bg-day img-namibia01-day01;
}

.c-bg__namibia01-day02 {
  @mixin bg-day img-namibia01-day02;
}

.c-bg__namibia01-day03 {
  @mixin bg-day img-namibia01-day03;
}

.c-bg__namibia01-day05 {
  @mixin bg-day img-namibia01-day05;
}

.c-bg__namibia01-day06 {
  @mixin bg-day img-namibia01-day06;
}

.c-bg__namibia01-day07 {
  @mixin bg-day img-namibia01-day07;
}

.c-bg__namibia01-day08 {
  @mixin bg-day img-namibia01-day08;
}

.c-bg__namibia01-day09 {
  @mixin bg-day img-namibia01-day09;
}

.c-bg__namibia01-day11 {
  @mixin bg-day img-namibia01-day11;
}

.c-bg__namibia01-day13 {
  @mixin bg-day img-namibia01-day13;
}

.c-bg__namibia01-day14 {
  @mixin bg-day img-namibia01-day14;
}

/*
  Namibia tour 02
*/
.c-bg__namibia02-day01 {
  @mixin bg-day img-namibia02-day01;
}

.c-bg__namibia02-day02 {
  @mixin bg-day img-namibia02-day02;
}

.c-bg__namibia02-day04 {
  @mixin bg-day img-namibia02-day04;
}

.c-bg__namibia02-day05 {
  @mixin bg-day img-namibia02-day05;
}

.c-bg__namibia02-day07 {
  @mixin bg-day img-namibia02-day07;
}

.c-bg__namibia02-day08 {
  @mixin bg-day img-namibia02-day08;
}

.c-bg__namibia02-day10 {
  @mixin bg-day img-namibia02-day10;
}

.c-bg__namibia02-day11 {
  @mixin bg-day img-namibia02-day11;
}

.c-bg__namibia02-day13 {
  @mixin bg-day img-namibia02-day13;
}

.c-bg__namibia02-day15 {
  @mixin bg-day img-namibia02-day15;
}

.c-bg__namibia02-day16 {
  @mixin bg-day img-namibia02-day16;
}

.c-bg__namibia02-day17 {
  @mixin bg-day img-namibia02-day17;
}

/*
  Namibia tour 03
*/
.c-bg__namibia03-day01 {
  @mixin bg-day img-namibia03-day01;
}

.c-bg__namibia03-day02 {
  @mixin bg-day img-namibia03-day02;
}

.c-bg__namibia03-day03 {
  @mixin bg-day img-namibia03-day03;
}

.c-bg__namibia03-day04 {
  @mixin bg-day img-namibia03-day04;
}

.c-bg__namibia03-day05 {
  @mixin bg-day img-namibia03-day05;
}

.c-bg__namibia03-day06 {
  @mixin bg-day img-namibia03-day06;
}

.c-bg__namibia03-day07 {
  @mixin bg-day img-namibia03-day07;
}

.c-bg__namibia03-day09 {
  @mixin bg-day img-namibia03-day09;
}

.c-bg__namibia03-day10 {
  @mixin bg-day img-namibia03-day10;
}

.c-bg__namibia03-day11 {
  @mixin bg-day img-namibia03-day11;
}

.c-bg__namibia03-day12 {
  @mixin bg-day img-namibia03-day12;
}

.c-bg__namibia03-day13 {
  @mixin bg-day img-namibia03-day13;
}

.c-bg__namibia03-day14 {
  @mixin bg-day img-namibia03-day14;
}

.c-bg__namibia03-day15 {
  @mixin bg-day img-namibia03-day15;
}

/*
  Namibia tour 04
*/
.c-bg__namibia04-day01 {
  @mixin bg-day img-namibia04-day01;
}

.c-bg__namibia04-day02 {
  @mixin bg-day img-namibia04-day02;
}

.c-bg__namibia04-day03 {
  @mixin bg-day img-namibia04-day03;
}

.c-bg__namibia04-day04 {
  @mixin bg-day img-namibia04-day04;
}

.c-bg__namibia04-day05 {
  @mixin bg-day img-namibia04-day05;
}

.c-bg__namibia04-day06 {
  @mixin bg-day img-namibia04-day06;
}

.c-bg__namibia04-day07 {
  @mixin bg-day img-namibia04-day07;
}

.c-bg__namibia04-day09 {
  @mixin bg-day img-namibia04-day09;
}

.c-bg__namibia04-day10 {
  @mixin bg-day img-namibia04-day10;
}

.c-bg__namibia04-day11 {
  @mixin bg-day img-namibia04-day11;
}

.c-bg__namibia04-day12 {
  @mixin bg-day img-namibia04-day12;
}

.c-bg__namibia04-day13 {
  @mixin bg-day img-namibia04-day13;
}

.c-bg__namibia04-day14 {
  @mixin bg-day img-namibia04-day14;
}

.c-bg__namibia04-day15 {
  @mixin bg-day img-namibia04-day15;
}
