.c-compare-table {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-size: 0.875rem;
  grid-row: cards;

  @media (--tablet) {
    font-size: 1rem;
  }
}

.c-compare-table > table {
  position: relative;

  width: 100%;
  overflow: hidden;

  /* table-layout: fixed; */

  /* border: 1px solid var(--color-black); */

  /* border-radius: var(--border-radius); */
  border-collapse: separate;
  border-spacing: 0;

  /* box-shadow: var(--shadow-light); */
}

.c-compare-table > table tbody {
  display: block;
  width: 100%;
  height: 400px;
  overflow: auto;
  scrollbar-width: thin;

  @media (--tablet) {
    height: 500px;
  }
}

.c-compare-table > table thead tr {
  display: block;
  width: calc(100% - 8px);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
  transform: scale(1);
}

@supports (-ms-ime-align: auto) {
  .c-compare-table > table thead tr {
    display: block;
    width: calc(100% - 16px);
  }
}

.c-compare-table > table th {
  padding: 1rem;
  background: var(--color-orange);
  border: 1px solid var(--color-black);
}

.c-compare-table > table th:first-child {
  width: 100%;
  min-width: 180px;
  border-top-left-radius: var(--border-radius);

  @media (--tablet) {
    min-width: 220px;
  }
}

.c-compare-table > table th:last-child {
  border-top-right-radius: var(--border-radius);
}

.c-compare-table > table th:not(:first-child) {
  min-width: 106px;

  @media (--tablet) {
    min-width: 116px;
  }
}

.c-compare-table > table td {
  padding: 1rem;
  color: var(--color-black);
  font-weight: 600;
  text-align: center;

  /* background: var(--color-sand-light); */
  border: 1px solid var(--color-black);
}

.c-compare-table > table td:first-child {
  width: 100%;
  min-width: 180px;
  text-align: left;

  @media (--tablet) {
    min-width: 220px;
  }
}

.c-compare-table > table td:not(:first-child) {
  min-width: 106px;

  @media (--tablet) {
    min-width: 116px;
  }
}

.c-compare-table > table tr:nth-child(even) {
  background: var(--color-sand-light-dark);
}

.c-compare-table > table tr:nth-child(odd) {
  background: var(--color-sand-light);
}

.c-compare-table::-webkit-scrollbar-track {
  background-color: rgba(var(--color-black), 0.96);
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.c-compare-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--color-sand);
}

.c-compare-table::-webkit-scrollbar-thumb {
  background-color: var(--color-black);
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.c-compare-table::-webkit-scrollbar-corner {
  /*
  background-image: url(resources/corner.png);
  background-repeat: no-repeat;
  */
  background-color: var(--color-sand);
}

.c-compare-table > table tbody::-webkit-scrollbar-track {
  background-color: rgba(var(--color-black), 0.96);
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.c-compare-table > table tbody::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--color-sand);
}

.c-compare-table > table tbody::-webkit-scrollbar-thumb {
  background-color: var(--color-black);
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
