.c-logo {
  height: 70px;
  padding-top: 5px;

  @media (--mobile-m) {
    height: 70px;
  }

  @media (--tablet) {
    height: 100px;
  }
}
