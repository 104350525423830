.c-copyright {
  color: var(--color-white);
}

.c-copyright__text {
  margin: 0;
  color: var(--color-white);
  font-size: 0.75rem;
  line-height: 1;
}
