.c-equipment {
  display: flex;
  flex-direction: column;

  align-self: start;
  grid-column: content;
  grid-row: equipmment;

  @media (--tablet) {
    grid-row: 1 / -1;
  }
}

.c-equipment__btns {
  display: flex;
  justify-content: flex-end;
}
