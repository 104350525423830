.c-bg__h-about {
  @mixin bg-hero about;
}

.c-bg__hero-about {
  background-image: url(../img/hero-about-1024px.jpg);
  background-position: center center;

  @media (--laptop) {
    background-image: url(../img/hero-about-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/hero-about-1920px.jpg);
  }
}

.c-bg__about-01 {
  background-image: url(../img/section-about-01-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/section-about-01-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-about-01-1920px.jpg);
  }
}

.c-bg__about-02 {
  background-image: url(../img/section-about-02-1024px.jpg);
  background-position: left -230px center;

  @media (--laptop) {
    background-image: url(../img/section-about-02-1440px.jpg);
    background-position: left -100px center;
  }

  @media (--desktop-s) {
    background-image: url(../img/section-about-02-1920px.jpg);
    background-position: left center;
  }

  @media (--desktop) {
    background-image: url(../img/section-about-02-1920px.jpg);
  }
}

.c-bg__about-03 {
  background-image: url(../img/section-about-03-1024px.jpg);
  background-position: center;

  @media (--laptop) {
    background-image: url(../img/section-about-03-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-about-03-1920px.jpg);
  }
}
