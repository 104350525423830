.c-load-progress {
  display: none;
  justify-content: center;
  align-items: center;

  align-self: end;

  width: 100px;
  height: 30px;

  padding-top: 48px;

  grid-row: text / footer;
  grid-column: content / right-gutter;
  justify-self: center;

  @media (--desktop-s) {
    display: flex;
  }
}

.c-load-progress > span {
  color: var(--color-sand-light);
  font-size: 1.5rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.1;
  text-transform: uppercase;
}

.c-loader {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: var(--color-sand);
  border-radius: 50%;
  box-shadow: var(--shadow-light);
  animation: 1.2s scaleDown ease-in-out infinite;
}

.c-loader:nth-child(2) {
  margin: 0 15px;
  animation: 1.2s scaleDown ease-in-out infinite 0.15555s;
}

.c-loader:nth-child(3) {
  animation: 1.2s scaleDown ease-in-out infinite 0.3s;
}

@keyframes scaleDown {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
