.c-bg__hitech {
  background-image: url(../img/section-hitech-1024px.jpg);

  @media (--laptop) {
    background-image: url(../img/section-hitech-1440px.jpg);
  }

  @media (--desktop) {
    background-image: url(../img/section-hitech-1920px.jpg);
  }
}

.c-bg__hitech::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: linear-gradient(180deg, rgba(217, 174, 139, 0) 0%, #d9ae8b 66.67%);

  content: '';
}

.c-bg__s-hitech {
  @mixin bg-section hitech, left;
}
