.c-privacy {
  grid-column: content / right-gutter;
}

.c-privacy .c-section__title:not(:first-child) {
  margin-top: 3rem;
}

.c-privacy .c-section__subtitle {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  line-height: 1.2;
}
