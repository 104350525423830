.o-post {
  display: grid;
  width: 100%;

  grid-template-columns: [content] 1fr;

  row-gap: 1rem;

  @media (--desktop) {
    grid-template-columns: [content] 7fr [recent] 3fr;
  }
}
