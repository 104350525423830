.c-phone {
  display: block;
  margin-right: 1rem;

  @media (--tablet) {
    display: flex;
    align-items: center;

    margin-right: 1.5rem;
  }

  @media (--laptop) {
    margin-right: 0;
    margin-left: 4.25rem;
  }
}

.c-phone__icon {
  /* display: none; */
  height: 1.5rem;

  @media (--tablet) {
    display: block;
    height: 2rem;
    margin-right: 0.5rem;
  }
}

.c-phone__number {
  color: var(--color-yellow);
  font-size: 1rem;

  @media (--tablet) {
    font-size: 1.3125rem;
    line-height: 1;
  }

  @media (--laptop) {
    text-decoration: none;
  }
}
