.c-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  margin: 0 auto;

  color: var(--color-white);

  user-select: none;

  grid-row: text / footer;
  grid-column: content / right-gutter;

  @media (--hero) {
    align-items: flex-start;
  }
}

.c-hero--group {
  @media (--mobile-m) {
    justify-content: flex-start;
    padding-top: 2rem;
  }

  @media (--tablet) {
    padding-top: 3rem;
  }

  @media (--laptop) {
    padding-top: 3rem;
  }

  @media (--widescreen) {
    padding-top: 6rem;
  }
}

.c-hero__content {
  @media (--hero) {
    max-width: 485px;
    margin: 0;
  }

  @media (--tablet) {
    max-width: 920px;
  }
}

.c-hero__bg {
  /* position: absolute; */
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.c-hero__video::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.15);
  content: '';
}

.c-hero__title {
  display: inline-flex;
  flex-direction: column;

  margin: 0 auto 0.2rem;

  font-size: 2.4rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.1;

  /* letter-spacing: 4px; */
  text-align: center;
  text-transform: uppercase;

  @media (--hero) {
    align-items: flex-start;
    margin: 0 0 0.2rem;

    /* font-size: 3rem; */
    text-align: left;
  }

  @media (--tablet) {
    font-size: 4rem;
  }

  @media (--desktop) {
    font-size: 6rem;
  }
}

.c-hero__content > .c-hero__title {
  width: 100%;

  @media (--hero) {
    text-align: left;
  }

  @media (--tablet) {
    font-size: 4rem;
  }

  @media (--desktop) {
    font-size: 6rem;
  }
}

.c-hero__title span {
  position: relative;
  text-align: center;

  /* text-transform: none; */
  text-shadow: var(--shadow-text);

  @media (--hero) {
    text-align: left;
  }
}

.c-hero__content > .c-hero__title span {
  text-align: center;

  @media (--hero) {
    text-align: left;
  }
}

.c-hero__title--normal {
  text-align: left;
}

.c-hero__subtitle {
  width: 100%;

  margin: 0 auto 2rem;
  color: var(--color-white);
  font-weight: 500;

  font-size: 1.5rem;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 1.1;
  letter-spacing: 0.03rem;
  text-align: center;
  text-transform: uppercase;

  text-shadow: var(--shadow-text);

  @media (--hero) {
    margin: 0 0 2rem;
    font-size: 1.5rem;

    text-align: left;
  }

  @media (--tablet) {
    font-size: 2.25rem;
  }
}

.c-hero__second-title {
  margin: 0 auto 0.15rem;
  margin-bottom: 1rem;

  color: var(--color-white);

  font-weight: 500;
  font-size: 1.7rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.1;
  letter-spacing: 0.03rem;
  text-align: center;

  /* text-transform: uppercase; */
  text-shadow: var(--shadow-text);

  @media (--hero) {
    font-size: 2.5rem;
    text-align: left;
  }
}

.c-hero__date {
  font-weight: 400;
  font-size: 1.1rem;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 1.1;
  text-align: center;
  text-shadow: var(--shadow-text);

  @media (--hero) {
    margin: 0;
    font-size: 1.5rem;
    text-align: left;
  }
}

.c-hero__equipment-title {
  text-transform: uppercase;
}

.c-hero__text {
  margin: 0 0 2.5rem;
  text-align: center;
  text-shadow: var(--shadow-text);

  @media (--hero) {
    font-size: 1.125rem;
    text-align: left;
  }
}

.c-hero__price {
  display: block;
  margin: 0 auto;

  color: var(--color-white);
  font-weight: 400;
  font-size: 1.1rem;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--hero) {
    margin: 0;
    font-size: 1.5rem;
    text-align: left;
  }
}

.c-hero__buttons {
  /* position: relative; */
  display: flex;

  flex-direction: column;
  align-items: center;
  align-self: center;
  margin: 0 auto;

  @media (--mobile-m) {
    /* position: absolute; */
    bottom: 50px;
  }

  @media (--hero) {
    flex-direction: row;
  }

  @media (--tablet) {
    /* position: absolute; */
    bottom: 100px;
  }

  @media (--laptop) {
    margin-top: 3rem;
  }

  /* @media (--desktop) {
    bottom: 150px;
  } */

  /* @media (--desktop) {
    margin-top: 5rem;
  } */
}

.c-hero__buttons > * + * {
  margin-top: 1.5rem;

  @media (--hero) {
    margin-top: 0;
    margin-left: 3.5rem;
  }

  @media (--tablet) {
    margin-left: 5rem;
  }
}
