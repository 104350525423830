.c-form__list {
  margin-top: 0;
  margin-bottom: 2rem;
  padding-left: 0;
  list-style-type: none;
}

.c-form__list:last-child {
  margin-bottom: 0;
}

.c-form__list-item {
  margin-bottom: 1rem;
}

.c-form__list-item:last-child {
  margin-bottom: 0;
}

.c-form__sublist {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-top: 0;
  padding-left: 0;
  list-style-type: none;
}

.c-form__sublist-item {
  width: 100%;
  min-width: 288px;
  margin-bottom: 1rem;

  @media (--tablet) {
    max-width: 312px;
  }
}

.c-form__sublist-item:last-child {
  margin-bottom: 0;

  @media (--tablet) {
    max-width: none;
  }

  @media (--laptop) {
    max-width: 312px;
  }
}

.c-form__label {
  display: block;
}

.c-form__buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (--form-buttons) {
    flex-direction: row;
  }
}

.c-form__button {
  width: 228px;
  padding: 1rem 1rem;
}

.c-form__button:first-child {
  margin-bottom: 1.5rem;

  @media (--form-buttons) {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
}

.bg-error {
  border: solid 3px var(--color-red) !important;
}
