.c-dsection {
  display: grid;

  align-items: start;

  grid-template-columns: [text] 1fr;
  grid-template-rows: [text] min-content;

  grid-row: text;
  grid-column: content / right-gutter;
}

.c-dsection__cards-title {
  align-self: end;

  grid-row: cards-title / cards;
}

.c-dsection--full-height {
  align-self: center;

  grid-row: text / footer;
}

.c-dsection--left {
  /* @media (--tablet) {
    align-items: stretch;
    grid-template-columns: [text] 13fr [empty] 9fr;
  } */

  @media (--laptop) {
    grid-template-columns: [text] 14fr [empty] 15fr;
  }

  @media (--desktop) {
    grid-template-columns: [text] 12fr [empty] 15fr;
  }
}

.c-dsection--right {
  /* @media (--tablet) {
    align-items: stretch;
    grid-template-columns: [empty] 9fr [text] 13fr;
  } */

  @media (--laptop) {
    grid-template-columns: [empty] 15fr [text] 14fr;
  }

  @media (--desktop) {
    grid-template-columns: [empty] 15fr [text] 12fr;
  }
}

.c-dsection__text {
  grid-row: text;
  grid-column: text;
}

.c-dsection__text--left {
  @media (--laptop) {
    padding-right: 3rem;
  }
}

.c-dsection__text--right {
  @media (--laptop) {
    padding-left: 3rem;
  }
}

.c-dsection__cards {
  grid-row: cards;
  grid-column: 1 / -1;
}
