.tabset > input[type='radio'] {
  position: absolute;
  left: -200vw;
}

.tab-panel {
  padding: 1rem 0 0;

  @media (--mobile-m) {
    padding: 2rem 0 0;
  }

  @media (--tablet) {
    padding: 3rem 0 0;
  }
}

.tabset .tab-panel {
  display: none;
}

.tabset {
  overflow: visible;
}

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
  display: block;
}

.tabset > label {
  position: relative;
  display: inline-block;
  float: left;
  width: 50%;

  padding: 16px 10px;
  color: var(--color-orange);
  font-weight: 400;
  font-size: 1.125rem;
  font-family: Oswald, sans-serif;
  font-style: normal;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;

  background: var(--color-black);
  cursor: pointer;

  @media (--tablet) {
    font-size: 1.25rem;
  }
}

/* .tabset-new > label:first-of-type {
  border-right: 1px solid var(--color-orange);
} */

.tabset > label:hover,
.tabset > input:focus + label {
  color: var(--color-orange-hover);
  background: var(--color-black-hover);
}

.tabset > input:checked + label {
  color: var(--color-black);
  background: var(--color-sand-light);
}

.tab-panel__title {
  margin: 3rem 0 1.5rem;
  padding: 0 1rem;

  font-size: 1.5rem;
  line-height: 1.1;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  @media (--tablet) {
    font-size: 1.7rem;
  }
}

.tab-panel__title:first-child {
  margin-top: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
  content: ' ';
}

.tab-panel__list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 0.875rem;

  @media (--mobile-m) {
    font-size: 1rem;
  }

  @media (--tablet) {
    padding-left: 40px;
  }
}
