.c-section {
  display: grid;
  align-content: center;
  align-items: center;

  grid-template-columns: [text] 1fr;
  grid-template-rows: [body] 1fr;

  grid-row: text / footer;
  grid-column: content / right-gutter;

  @media (--tablet) {
    align-items: stretch;
    grid-template-rows: [body] auto;
  }

  @media (--laptop) {
    grid-template-columns: [text] 1fr [image] 1fr;
    grid-template-rows: [body] auto;
    column-gap: 2rem;
  }
}

.c-section__text {
  grid-row: body;
  grid-column: text / image;
}

.c-section__title {
  margin-top: 0;
  margin-bottom: 1rem;

  color: var(--color-black);
  font-weight: 500;
  font-size: 1.85rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.2;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--mobile-m) {
    font-size: 2.1rem;
  }

  @media (--tablet) {
    margin-bottom: 1rem;
    font-size: 2.5rem;
  }

  @media (--laptop) {
    font-size: 2.5rem;
    text-align: left;
  }

  @media (--desktop) {
    font-size: 3rem;
    text-align: left;
  }
}

.c-section__title--hitech {
  margin-top: 0;
  margin-bottom: 1rem;

  color: var(--color-black);
  font-weight: 500;
  font-size: 1.55rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1.2;
  text-transform: uppercase;
  text-shadow: var(--shadow-text);

  @media (--tablet) {
    margin-bottom: 2rem;
    font-size: 2.2rem;
  }

  @media (--laptop) {
    font-size: 2.2rem;
    text-align: left;
  }

  @media (--desktop) {
    font-size: 2.5rem;
  }
}

.c-section__title--small {
  margin: 3.5rem 0 0;

  font-weight: 400;
  font-size: 1.7rem;
  letter-spacing: 1px;
  text-shadow: none;

  @media (--tablet) {
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }

  @media (--laptop) {
    margin-top: 0;
  }
}

.c-section__title--center {
  text-align: center;
}

.c-section__subtitle {
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
  color: var(--color-green);
  font-weight: 500;
  font-size: 1.125rem;
  font-family: 'Oswald', sans-serif;
  line-height: 1;
  text-transform: uppercase;

  @media (--tablet) {
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
  }
}

.c-section__description {
  /* font-size: 0.875rem;

  @media (--mobile-m) {
    font-size: 1rem;
  } */
}

.c-section__list {
  margin-top: 0.5rem;
  font-size: 0.875rem;

  @media (--mobile-l) {
    font-size: 1rem;
  }
}

.c-section__list > * + * {
  margin-top: 0.5rem;
}

.c-section__price {
  font-weight: bold;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  line-height: 27px;
  letter-spacing: 0.03em;
}

.c-section__image {
  width: 100%;
  grid-row: body;
  grid-column: image;
}

.c-section__pic {
  width: 100%;
  height: auto;

  margin-top: 0.5rem;
  margin-bottom: 1rem;

  /* border: 1px solid var(--color-sand); */
  border-radius: var(--border-radius--small);

  /* box-shadow: var(--shadow-light); */
}

.c-section__tours {
  grid-row: body;
  grid-column: text / -1;

  align-self: start;
  justify-self: end;

  width: 100%;
  margin-top: 1rem;

  @media (--tablet) {
    width: 500px;
    margin-top: 2rem;
  }
}

.c-section--align-start {
  align-content: start;
}
