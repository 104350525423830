/* .c-price > * + * {
  margin-top: 2rem;
} */

.c-price {
  position: relative;
  transition: top 0.3s ease-in-out;
}

.c-price > .c-btn {
  width: 100%;
  max-width: none;
}

.c-price > * + * {
  margin-top: 0.5rem;

  @media (--tablet) {
    margin-top: 1rem;
  }
}

/* .c-price__section {
  padding: 1.5rem 0.93rem;
  background: var(--color-black);

  border-radius: var(--border-radius--small);
  box-shadow: var(--shadow-light);

  @media (--mobile-m) {
    padding: 1.5rem;
  }

  @media (--tablet) {
    padding: 2rem;
  }
}

.c-price__section > h2,
.c-price__section > h3 {
  margin-top: 0;
  color: var(--color-orange);
}

.c-price__section > h3 {
  margin-bottom: 0.5rem;
}

.c-price__section > h2 {
  font-size: 1.5rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);

  @media (--tablet) {
    font-size: 1.7rem;
  }
}

.c-price__section > ul {
  margin: 0;
  padding-left: 20px;
  font-size: 0.875rem;
}

.c-price__section > ul a {
  color: var(--color-white);
  transition: color 0.25s;
}

.c-price__section > ul a:hover {
  color: var(--color-orange);
}

.c-price__section > table {
  width: 100%;

  overflow: hidden;
  font-size: 0.875rem;
  border: 1px solid var(--color-black);
  border-radius: var(--border-radius--small);
  box-shadow: var(--shadow-light);

  @media (--mobile-m) {
    font-size: 1rem;
  }
}

.c-price__section > table th {
  width: 50%;
  padding: 0.7rem;
  font-weight: 600;
  line-height: 1.2rem;
  background: var(--color-orange);
  border: 1px solid var(--color-black);

  @media (--mobile-m) {
  }
}

.c-price__section > table td {
  width: 50%;
  padding: 0.7rem 0.2rem;
  color: var(--color-black);
  font-weight: 400;
  line-height: 1.2rem;
  text-align: center;
  background: var(--color-sand-light);
  border: 1px solid var(--color-black);
}

.c-price__section > .c-btn {
  width: 100%;
  max-width: 100%;
  margin-top: 1.5rem;
} */

.c-price__section {
  /* margin: 0 0.93rem;

  @media (--mobile-m) {
    margin: 0 1.5rem;
  }

  @media (--tablet) {
    margin: 0;
  } */
}

.c-price__title {
  color: var(--color-black);
  font-weight: 500;
  font-size: 1.3rem;
  font-family: Oswald, sans-serif;

  @media (--tablet) {
    font-size: 1.5rem;
  }
}

.c-price__value {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.c-price__value > p {
  color: var(--color-green);
  font-weight: 600;
  font-size: 2rem;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 1;
  transition: color 0.25s;

  @media (--tablet) {
    font-size: 3rem;
  }

  @media (--laptop) {
    font-size: 4rem;
  }

  /* text-shadow: var(--shadow-text); */
}

.c-price__value:hover > p {
  @media (--tablet) {
    color: var(--color-orange);
  }
}

.c-price__value > svg {
  width: 20px;
  margin-left: 0.2rem;
  transition: fill 0.25s, transform 0.25s;
  fill: var(--color-green);

  @media (--tablet) {
    width: 28px;
  }

  @media (--laptop) {
    width: 32px;
  }
}

.c-price__value:hover > svg {
  @media (--tablet) {
    fill: var(--color-orange);
  }
}

.c-price__more {
  display: none;

  & > ul {
    margin: 0 0 0.5rem;
    padding-left: 0;
    list-style: none;
  }

  & > ul > li {
    color: var(--color-black);
  }

  & li > p {
    display: inline-flex;
    align-items: center;
  }

  & span:first-child {
    color: var(--color-green);
    font-weight: 600;
    font-size: 1rem;
    font-family: 'Roboto Condensed', sans-serif;

    @media (--mobile-m) {
      font-size: 1.2rem;
    }

    @media (--tablet) {
      font-size: 1.8rem;
    }

    @media (--laptop) {
      font-size: 1.44rem;
    }

    @media (--desktop) {
      font-size: 2rem;
    }
  }

  & sup {
    font-size: 1rem;
  }

  & > p {
    color: var(--color-black);
    font-size: 0.875rem;
  }
}

.c-price__season {
  margin-top: 1rem;

  & > p {
    font-size: 1rem;
    font-family: 'Oswald', sans-serif;
    font-weight: 500;

    @media (--tablet) {
      font-size: 1.2rem;
    }
  }
}

.c-price__season:first-child {
  margin-top: 0;
}

.c-price__date > p {
  color: var(--color-orange);
  font-weight: 600;
  font-size: 1.3rem;
  font-family: 'Roboto Condensed', sans-serif;

  @media (--tablet) {
    font-size: 1.5rem;
  }
}

.c-price__details {
  position: relative;
  display: block;

  color: var(--color-black);

  & > div:first-child {
    display: inline-flex;
    align-items: center;

    cursor: pointer;
  }

  & > div:first-child > svg {
    width: 18px;
    margin-top: 6px;
    margin-left: 4px;
    transition: fill 0.25s, transform 0.25s;
  }

  & h3 {
    margin: 0;
    font-weight: 500;
    font-size: 1.3rem;
    font-family: Oswald, sans-serif;
    line-height: 1;
    transition: color 0.25s;

    @media (--tablet) {
      font-size: 1.5rem;
    }
  }

  & > div:first-child:hover > h3 {
    @media (--laptop) {
      color: var(--color-orange);
    }
  }

  & > div:first-child:hover > svg {
    @media (--laptop) {
      fill: var(--color-orange);
    }
  }

  & ul {
    margin: 0;
    padding-left: 20px;
  }

  & li {
    font-size: 0.875rem;
    line-height: 1.4;
    letter-spacing: 0.3px;

    @media (--mobile-m) {
      font-size: 1rem;
    }
  }
}

.c-price-content {
  position: relative;
  display: none;
}

.c-price > .c-btn {
  margin-top: 1.5rem;
  
  @media(--tablet) {
    margin-top: 2rem;  
  }
}

.c-price__features {
  display: flex;
  flex-wrap: wrap;
}

.c-price__features > .c-price__section {
  flex: 1 1 320px;

  @media (--mobile-l) {
    flex-basis: 400px;
  }

  @media (--tablet) {
    flex-basis: 280px;
  }

  @media (--laptop) {
    flex-basis: 320px;
  }

  @media (--desktop) {
    flex-basis: 600px;
  }
}

.c-price__features > .c-price__section:not(:first-child) {
  margin-top: 2rem;

  @media (--tablet) {
    margin-top: 0;
    margin-left: 2rem;
  }

  @media (--laptop) {
    margin-top: 2rem;
    margin-left: 0;
  }
}
