.c-day-bt {
  position: relative;

  overflow: hidden;
  background: var(--color-sand-light-dark);

  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
}

.c-day-bt__bg {
  position: relative;
  width: 100%;
}

.c-day-bt__header {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0;
  overflow: hidden;

  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
  cursor: pointer;

  user-select: none;

  @media (--tablet) {
  }

  /* @media (--desktop-s) {
    height: 330px;
  } */
}

.c-day-bt__header::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 100%;

  /* background: rgba(0, 0, 0, 0.15); */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 62%, rgba(0, 0, 0, 0.15) 69%, rgba(0, 0, 0, 0.5) 100%);

  transition: background 0.25s ease-in-out;

  content: '';
}

.c-day-bt__header:hover::after {
}

.c-day-bt__content {
  position: absolute;
  bottom: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  text-align: center;
}

.c-day-bt__body {
  position: relative;
  display: none;
  padding: 0 1rem;

  &::before {
    display: block;
    height: 10px;
    content: '';
  }

  @media (--mobile-m) {
    padding: 0 1rem;
  }

  @media (--hero) {
    padding: 0 1.5rem;
    font-size: 1rem;
  }
}

.c-day-bt__body p {
  font-size: 0.875rem;
  line-height: 1.4;

  @media (--tablet) {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.c-day-bt__footer {
  display: flex;
  justify-content: center;
}

.c-day-bt__wraper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.c-day-bt__title {
  display: flex;
  align-items: center;

  width: 100%;
  margin: 0;
  /* padding: 1rem 0 0; */

  font-family: Oswald, sans-serif;
  /* text-align: center; */
  /* text-shadow: var(--shadow-text); */

  /* @media (--mobile-m) {
    padding: 8% 0 0;
  } */

  @media (--tablet) {
    align-self: center;
    /* padding: 8% 0 0; */
  }
}

.c-day-bt__num-dest {
  position: relative;
  display: flex;
  flex: 2 0 72px;
  justify-content: center;
  align-items: center;

  height: 44px;
  padding: 0.5rem;

  text-transform: uppercase;

  @media (--tablet) {
    height: 54px;
  }

  @media (--laptop) {
    flex: 2 0 84px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background: var(--color-green-80);
    content: '';
  }

  & > p {
    color: var(--color-white);
    font-size: 0.75rem;

    @media (--tablet) {
      font-size: 1rem;
    }
  }
}

.c-day-bt__destination {
  position: relative;
  display: flex;
  flex: 20 1 auto;
  flex-direction: column;
  justify-content: center;

  height: 44px;

  padding: 0 0.5rem;

  @media (--tablet) {
    height: 54px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background: var(--color-sand-80);
    content: '';
  }

  & > p:first-child {
    font-size: 0.75rem;
    line-height: 1;
    text-transform: uppercase;

    @media (--tablet) {
      margin-bottom: 0.2rem;
      font-size: 1rem;
    }
  }

  & > p:last-child {
    font-size: 0.625rem;
    line-height: 1;

    @media (--tablet) {
      font-size: 0.8rem;
    }
  }
}


/* .c-day-bt__title > span {
  margin: 0.15rem 0 0.5rem;
  color: var(--color-white);
  font-weight: 400;
  font-size: 0.875rem;
  font-family: 'Roboto Condensed', sans-serif;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;

  @media (--hero) {
    font-size: 1rem;
  }
}

.c-day-bt__title > span:first-child {
  margin: 0;
  color: var(--color-white);
  font-weight: 500;
  font-size: 1.3rem;
  font-family: Oswald, sans-serif;
  line-height: 1.3;
  text-transform: uppercase;

  @media (--mobile-m) {
    font-size: 1.5rem;
  }

  @media (--hero) {
    font-size: 2rem;
  }
}

.c-day-bt__title > span:last-child,
.c-day-bt__title > span:nth-child(3) {
  margin: 0;
  color: var(--color-white);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  text-transform: initial;

  @media (--hero) {
    font-size: 1rem;
  }
} */

.c-day-bt__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 0.75rem 0.5rem;

  @media (--tablet) {
    padding: 0 1.5rem 1rem;
  }
}

.c-day-bt__info-list {
  margin: 0;
  padding: 0;

  color: var(--color-white);
  list-style-type: none;

  & > li {
    display: flex;
    align-items: center;
  }

  & > li > p {
    font-weight: 300;
    font-size: 0.75rem;
    font-family: Oswald, sans-serif;
    line-height: 1.2;
    letter-spacing: 1px;

    @media (--hero) {
      font-size: 0.875rem;
    }
  }

  & > * + * {
    margin-top: 0.2rem;
  }
}

.c-day-bt__icon {
  display: inline-block;
  width: 20px;

  margin-right: 0.5rem;

  @media (--hero) {
    width: 32px;
  }
}

.c-day-bt__accommodation-icon {
  display: inline-block;
  width: 40px;

  @media (--hero) {
    width: 70px;
  }
}
