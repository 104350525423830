.o-cards {
  grid-row: cards;
}

/* .o-cards > * + * {
  margin-left: 2rem;

  @media (--tablet) {
    margin-left: 0;
  }

  @media (--laptop) {
    margin-left: 2rem;
  }
}

.o-cards--mobile {
  display: block;

  @media (--tablet) {
    display: none;
  }
}

.o-cards--desktop {
  display: none;

  @media (--tablet) {
    display: flex;
  }
} */
